import api from "../infrastructure/Api";
import {UPDATE_CHALLENGE_QUESTION_IMAGE} from "./types/ActionTypes";

export const GetImageFile = (fileName, questionId) => async (dispatch) => {
    const data = await api.getImage({fileName});

    dispatch({
        type: UPDATE_CHALLENGE_QUESTION_IMAGE,
        questionId: questionId,
        imageBase64String: data.imageBase64String,
        contentType: data.contentType
    });

    return data;
};