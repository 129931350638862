import api from "../infrastructure/Api";
import {SET_CHALLENGE, UPDATE_CHALLENGE_QUESTION} from "./types/ActionTypes";
import {SET_TABLE_DATA} from "../components/Containers/ActionTypes";

export const CreateChallenge = (isExam,
                                questionsCount,
                                challengeComplexity,
                                challengeCompilation,
                                topicIds,
                                showResult) => async (dispatch) => {
    const challenge = await api.createChallenge({
        challengeType: isExam ? 2 : 1,
        questionsQuantity: questionsCount,
        challengeComplexity: challengeComplexity,
        compilation: challengeCompilation,
        showResult: showResult,
        topicIds: topicIds
    });

    dispatch({
        type: SET_CHALLENGE,
        challenge: challenge
    });

    return challenge.id;
}

export const CancelChallenge = (challengeId) => async (dispatch) => {
    return await api.cancelChallenge(challengeId);
}

export const GetChallenge = (challengeId) => async (dispatch) => {
    const challenge = await api.getChallenge(challengeId);

    dispatch({
        type: SET_CHALLENGE,
        challenge: challenge
    });

    return challenge;
}

export const CheckInChallengeQuestion = (challengeId, questionId, answers) => async (dispatch) => {
    const questionResult = await api.checkIn({
        challengeId,
        questionId,
        answerChoices: answers
    });

    dispatch({
        type: UPDATE_CHALLENGE_QUESTION,
        questionId: questionResult.id,
        answers: questionResult.answers
    });

    return questionResult;
}

export const FinishChallenge = (challengeId) => async (dispatch) => {
    const challenge = await api.finish(challengeId);

    dispatch({
        type: SET_CHALLENGE,
        challenge: challenge
    });

    return challenge;
}

export const GetMyChallenges = (state) => async (dispatch) => {
    const fetchResult = await api.getMyChallenges({
        SortField: state.sortField,
        IsAscendingSortOrder: state.sortAscending,
        SkipRecords: state.currentPage * state.takeRows,
        KeepRecords: state.takeRows,
    });

    dispatch({
        type: SET_TABLE_DATA,
        tableData: fetchResult.pagedElements,
        tableTotalDataCount: fetchResult.count,
    });
}

export const GetQuestionExplanation = (challengeId, questionId) => async (dispatch) => {
    return await api.getQuestionExplanation(challengeId, questionId);
}