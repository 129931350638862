export const modalSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export const updateButtonSx = {
    marginTop: "20px",
    width: "100%",
    'color': "var(--success-primary)",
    borderColor: "var(--success-secondary)",
    '&:hover': {
        background: "var(--success-transparent)",
        borderColor: "var(--success-primary)",
    },
}

export const manageButtonSx = {
    'color': "var(--color-red)",
    marginTop: "7px",
    borderColor: "var(--color-red)",
    '&:hover': {
        background: "var(--color-red-transparent-hover)",
        borderColor: "var(--color-red)",
    },
}

export const paperSx = {
    bgcolor: 'background.paper',
    borderRadius: "5px",
    padding: '16px 32px 24px 32px',
    minWidth: "550px",
    position: 'absolute',
    ['@media (max-width:700px)']: {
        minWidth: '300px'
    }
}

export const inputElement = {
    marginBottom: "10px"
};
