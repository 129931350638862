import React from "react";
import PropTypes from "prop-types";
import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";

import classes from "./PreviewTableHeader.module.css";

function PreviewTableHeader(props) {
    const {orderBy, isAscending, headElements, handleSort} = props;

    const sortHandler = (orderByProp) => () => {
        let isAsc = orderBy === orderByProp && !isAscending;

        handleSort(orderByProp, isAsc);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    className={classes.headerCell}
                    key="position"
                    align="right"
                    aria-sort="none">
                    №
                </TableCell>
                {headElements.map((headCell) => {
                        const currentCell = orderBy === headCell.id;
                        if (headCell.sortArea === "none") {
                            return (
                                <TableCell
                                    className={classes.headerCell}
                                    key={headCell.id}
                                    align={headCell.align}
                                    aria-sort={headCell.sortArea}>{headCell.label}
                                </TableCell>
                            );
                        } else {
                            return (
                                <TableCell
                                    className={classes.headerCell}
                                    key={headCell.id}
                                    align={headCell.align}
                                    aria-sort={headCell.sortArea}
                                    sortDirection={currentCell ? (isAscending ? "asc" : "desc") : false}>
                                    <TableSortLabel
                                        active={currentCell}
                                        direction={currentCell ? isAscending ? "asc" : "desc" : "desc"}
                                        onClick={sortHandler(headCell.id)}>
                                        {headCell.label}
                                        {currentCell ? (
                                            <span className={classes.visuallyHidden}>
                                        {isAscending === true ? "sorted descending" : "sorted ascending"}
                                    </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            );
                        }
                    }
                )}
            </TableRow>
        </TableHead>
    );
}

PreviewTableHeader.propTypes = {
    headElements: PropTypes.array.isRequired,
    handleSort: PropTypes.func.isRequired,
    orderBy: PropTypes.string,
    isAscending: PropTypes.bool.isRequired,
};

export default PreviewTableHeader;