import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styleClasses from "./ProfessionsPickerModal.modal.css";
import {chipsSelectionAreaSx, pickerInputSx, manageButtonSx, modalSx, paperSx, updateButtonSx} from "./styles";
import ProfessionChipsArea from "../ProfessionChipsArea/ProfessionChipsArea";
import {requestHandler} from "../../infrastructure/requestHandler";
import {GetProfessions} from "../../actions/CompanyActions";
import {useDispatch} from "react-redux";
import {Backdrop, Box, Button, Fade, FormControl, InputLabel, MenuItem, Modal, Select} from "@mui/material";

function ProfessionsPickerModal(props) {

    const [isModalOpened, openModal] = useState(false);
    const [userProfessions, updateUserProfessions] = useState([]);
    const [userStaticProfessionsList, updateUserStaticProfessionsList] = useState([]);
    const [professionsList, setProfessionsList] = useState([]);
    const [dropdownProfessions, setDropdownProfessions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const setupProfessions = async () => {
            const result = await requestHandler(GetProfessions(), dispatch);

            setProfessionsList(result.professions.map((prof) => {
                return {
                    id: prof.id,
                    title: prof.title
                };
            }))
        };

        setupProfessions();
    }, []);

    useEffect(() => {

        const pastedProfession = [];
        if (props.userProfessions !== undefined) {
            props.userProfessions.forEach((profession) => {
                if (profession !== undefined) {
                    pastedProfession.push({
                        id: profession.id,
                        title: profession.title
                    });
                }
            });

            const withoutDuplicates = [];
            professionsList.forEach((prof) => {
                if (pastedProfession.find(p => p.id === prof.id) === undefined) {
                    withoutDuplicates.push(prof);
                }
            })

            updateUserProfessions(pastedProfession);
            updateUserStaticProfessionsList(pastedProfession);
            setDropdownProfessions(withoutDuplicates);
        }
    }, [props.userProfessions, professionsList]);

    const handleSaveProfessionsList = () => {
        props.saveProfessionsListHandler(userProfessions);
    };

    const handleAddProfession = (event) => {
        const profession = event.target;

        let dropdownCopy = [...dropdownProfessions];
        const foundElement = dropdownCopy.find(p => p.id === profession.value);

        dropdownCopy.splice(dropdownCopy.indexOf(foundElement), 1);

        setDropdownProfessions(dropdownCopy);
        updateUserProfessions([...userProfessions, foundElement])
    };

    const handleRemoveProfession = (profession) => {
        const foundProfession = userProfessions.find(p => p.id === profession.id);

        let userProfessionsCopy = [...userProfessions];
        userProfessionsCopy.splice(userProfessionsCopy.indexOf(foundProfession), 1);

        setDropdownProfessions([...dropdownProfessions, foundProfession]);
        updateUserProfessions(userProfessionsCopy);
    };

    const handleOpen = () => {
        openModal(true);
    };

    const handleClose = () => {
        openModal(false);
    };

    return (
        <div>
            <div className={styleClasses.mainElements}>
                <ProfessionChipsArea areaDisabled={true}
                                     professions={userStaticProfessionsList}/>
                <Button variant="outlined" sx={manageButtonSx} onClick={handleOpen}>
                    Управлять профессиями
                </Button>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                sx={modalSx}
                open={isModalOpened}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={isModalOpened}>
                    <Box sx={paperSx}>
                        <div className={styleClasses.modalChipsArea}>
                            <ProfessionChipsArea areaDisabled={false}
                                                 professions={userProfessions}
                                                 handleDelete={handleRemoveProfession}/>
                        </div>
                        <FormControl variant="standard"  sx={chipsSelectionAreaSx}>
                            <InputLabel shrink id="professions-select-label">Выберите профессию</InputLabel>
                            <Select
                                name="professions"
                                sx={pickerInputSx}
                                onChange={handleAddProfession}
                                value=""
                                labelId="professions-select-label"
                                label="Choose profession">
                                {dropdownProfessions.map(profession => {
                                    return <MenuItem value={profession.id}>{profession.title}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <Button variant="outlined"
                                sx={updateButtonSx}
                                onClick={handleSaveProfessionsList}>Обновить профессии</Button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

ProfessionsPickerModal.propTypes = {
    userProfessions: PropTypes.array.isRequired,
    saveProfessionsListHandler: PropTypes.func.isRequired
};

export default ProfessionsPickerModal;