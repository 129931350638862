import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {requestHandler} from "../../../infrastructure/requestHandler";
import {CheckInChallengeQuestion, FinishChallenge, GetChallenge} from "../../../actions/ChallengeActions";

import MainContainer from "../../../components/Containers/MainContainer/MainContainer";
import ChallengeProgressBar from "../../../components/ChallengeProgressBar/ChallengeProgressBar";

import classes from './ChallengePage.module.css';
import SeparationLine from "../../../components/SeparationLine/SeparationLine";
import ChallengeQuestion from "../../../components/ChallengeQuestion/ChallengeQuestion";
import {
    FinishChallengeButton,
    GoBackButton,
    NextQuestionButton,
    SubmitAndNextQuestionButton,
    SubmitQuestionButton
} from "./styles";
import ChallengeQuestionsList from "../../../components/ChallengeQuestionsList/ChallengeQuestionsList";
import FinishStats from "../../../components/FinishStats/FinishStats";
import {useHistory} from "react-router-dom";
import {SNACKBAR_ERROR_APPEARED} from "../../../actions/types/ActionTypes";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {useMediaQuery} from "@mui/material";

export default function ChallengePage(props) {

    const [loadingState, setLoadingState] = useState(true);
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [allowEditing, setAllowEditing] = useState(false);
    const [initialAnswers, setInitialAnswers] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [answerChanged, setAnswerChanged] = useState(false);
    const [stateUpdated, updateState] = useState(false);
    const [finishChallenge, setFinishChallenge] = useState(false);
    const matches = useMediaQuery('(max-width:1050px)');

    const challengeId = props.match.params.id;

    const challengeState = useSelector(state => state.ChallengeReduce);
    const dispatch = useDispatch();

    const history = useHistory();

    const initQuestion = (challenge, questionNumberChoice) => {
        const selectedQuestion = challenge.questions[questionNumberChoice];

        setCurrentQuestionNumber(questionNumberChoice);
        setCurrentQuestion(selectedQuestion);
        setLoadingState(false);

        setAllowEditing(challenge.status === 1 && selectedQuestion.answers.every(a => a.status === 0 || a.status === 1));

        const chosenAnswers = [];
        selectedQuestion.answers.forEach(answer => {
            if (answer.status !== 0) {
                chosenAnswers.push(answer.id);
            }
        })

        setAnswerChanged(false);
        setInitialAnswers(chosenAnswers);
    }

    useEffect(() => {
        if (!challengeState.id || challengeState.id !== challengeId) {
            const getChallenge = async (id) => {
                const challenge = await requestHandler(GetChallenge(id), dispatch);
                initQuestion(challenge, currentQuestionNumber);
            }

            getChallenge(challengeId);
        } else {
            initQuestion(challengeState, currentQuestionNumber);
        }
    }, [challengeId])

    useEffect(() => {
        const fetchFinish = async () => {
            if (challengeState.status === 1) {
                let data = {};
                if (answerChanged) {
                    data = await requestHandler(CheckInChallengeQuestion(challengeId, currentQuestion.id, selectedAnswers), dispatch);
                }

                if (data) {
                    await requestHandler(FinishChallenge(challengeId), dispatch);
                    updateState(!stateUpdated);
                    setAllowEditing(false);
                } else {
                    dispatch({
                        type: SNACKBAR_ERROR_APPEARED,
                        messages: ["Произошла ошибка во время завершения испытания. Попробуйте снова"],
                    })
                }
            }
        }

        if (finishChallenge === true) {
            fetchFinish();
        }
    }, [finishChallenge])

    useEffect(() => {
        if (finishChallenge === true) {
            initQuestion(challengeState, 0);
        }
    }, [finishChallenge, challengeState.status])

    const handleAnswerStatusChanging = (answerId) => {

        const actualAnswers = [];
        const updatingQuestion = currentQuestion;
        updatingQuestion.answers.forEach(answer => {
            if (answer.id === answerId) {
                answer.status = answer.status === 0 ? 1 : 0;
            } else if (!updatingQuestion.isMultipleAnswer) {
                answer.status = 0;
            }

            if (answer.status !== 0) {
                actualAnswers.push(answer.id);
            }
        });

        setCurrentQuestion(updatingQuestion);
        updateState(!stateUpdated);
        setSelectedAnswers(actualAnswers);
        setAnswerChanged(actualAnswers.length !== initialAnswers.length ||
            !actualAnswers.every(actualId => initialAnswers.some(initialId => actualId === initialId)));
    }

    const handleReturnBack = () => {
        history.goBack();
    }

    const handleSubmitQuestionAnswer = async () => {
        if (answerChanged) {
            await requestHandler(CheckInChallengeQuestion(challengeId, currentQuestion.id, selectedAnswers), dispatch);

            if (challengeState.showResult) {
                setAllowEditing(false);
                updateState(!stateUpdated);
            }
        }
    }

    const handleChangingPresentingQuestion = () => {
        if (currentQuestionNumber + 1 <= challengeState.questions.length) {
            initQuestion(challengeState, currentQuestionNumber + 1);

        }
    }

    const handleSubmitAnswerAndChangingQuestion = () => {
        handleSubmitQuestionAnswer();
        handleChangingPresentingQuestion();
    }

    const handleInconsistentQuestionPresentation = (questionIndex) => {
        handleSubmitQuestionAnswer();
        if (questionIndex + 1 <= challengeState.questions.length) {
            initQuestion(challengeState, questionIndex);
        }
    }

    const handleFinishChallenge = () => {
        setFinishChallenge(true);
    }

    if (loadingState) {
        return <LoadingSpinner/>
    }

    return (
        <MainContainer alignment={matches ? "vertical" : "horizontal"}>
            <div className={classes.leftside}>
                <ChallengeProgressBar duration={challengeState.duration}
                                      startDate={challengeState.startDateTime}
                                      allowStart={challengeState.status === 1}
                                      currentQuestionNumber={currentQuestionNumber + 1}
                                      endTimeCallback={handleFinishChallenge}/>
                <SeparationLine/>
                <ChallengeQuestion question={currentQuestion}
                                   allowEditing={allowEditing}
                                   handleUpdatingAnswerChoice={handleAnswerStatusChanging}
                                   challengeId={challengeId}
                                   allowDisplayExplanation={challengeState.status !== 1 && challengeState.type !== 2}/>
                <div>
                    {currentQuestionNumber + 1 === challengeState.questions.length ? <></> :
                        challengeState.status === 1 ?
                            challengeState.showResult ?
                                allowEditing ? <SubmitQuestionButton
                                        onClick={handleSubmitQuestionAnswer}>Ответить</SubmitQuestionButton> :
                                    <NextQuestionButton onClick={handleChangingPresentingQuestion}>Следующий
                                        вопрос</NextQuestionButton>
                                : <SubmitAndNextQuestionButton onClick={handleSubmitAnswerAndChangingQuestion}>Следующий
                                    вопрос</SubmitAndNextQuestionButton> :
                            <NextQuestionButton onClick={handleChangingPresentingQuestion}>Следующий
                                вопрос</NextQuestionButton>
                    }
                </div>
            </div>
            <div className={classes.rightside}>
                {challengeState.status === 1 ? <></> :
                    (<div className={classes.statsArea}>
                        <FinishStats questions={challengeState.questions}
                                     challengeEndTime={challengeState.endDateTime}
                                     challengeStartTime={challengeState.startDateTime}/>
                        <SeparationLine/>
                    </div>)}
                <ChallengeQuestionsList questions={challengeState.questions}
                                        currentActiveQuestionNumber={currentQuestionNumber}
                                        handleChangePresentingQuestion={handleInconsistentQuestionPresentation}
                                        stateUpdated={stateUpdated}/>
                <div className={classes.finishBottomArea}>
                    <SeparationLine/>
                    {challengeState.status === 1 ?
                        <FinishChallengeButton onClick={handleFinishChallenge}>Закончить</FinishChallengeButton> :
                        <GoBackButton onClick={handleReturnBack}>Вернуться назад</GoBackButton>
                    }
                </div>
            </div>
        </MainContainer>
    );
}