import React, {useEffect, useState} from 'react';
import PropType from 'prop-types';
import {useDispatch} from "react-redux";
import {GetImageFile} from "../../actions/FileActions";
import SeparationLine from "../SeparationLine/SeparationLine";
import {requestHandler} from "../../infrastructure/requestHandler";
import classes from './QuestionPresentation.module.css';
import {useMediaQuery} from "@mui/material";

function base64ToBlobImage(base64, contentType) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], {type: contentType});
}

function QuestionPresentation(props) {
    const [] = useState();

    const matches = useMediaQuery('(max-width:1050px)');

    const dispatch = useDispatch();
    const [displayImage, setDisplayImage] = useState(undefined);

    useEffect(() => {
        if (props.imagePath) {
            if (!props.imageBase64String || !props.imageContentType) {
                const loadImage = async () => {
                    let imageResult = await requestHandler(GetImageFile(props.imagePath, props.questionId), dispatch);

                    const imageBlob = base64ToBlobImage(imageResult.imageBase64String, imageResult.contentType);
                    setDisplayImage(URL.createObjectURL(imageBlob));
                }

                loadImage();
            } else {
                const imageBlob = base64ToBlobImage(props.imageBase64String, props.imageContentType);
                setDisplayImage(URL.createObjectURL(imageBlob));
            }
        } else {
            setDisplayImage(undefined);
        }
    }, [props.questionId]);

    if (displayImage !== undefined) {
        return (
            <div className={classes.mainArea}>
                <div className={classes.presentingArea}>
                    <img alt="question's image" className={classes.questionImage}
                         src={displayImage}/>
                    <p className={classes.questionText}>{props.questionText}</p>
                </div>
                <SeparationLine variant={matches ? 'horizontal' : 'vertical'}/>
            </div>
        )
    }

    return (
        <div>
            <p className={classes.questionText}>{props.questionText}</p>
            <SeparationLine/>
        </div>
    );
}

QuestionPresentation.propTypes = {
    questionId: PropType.string.isRequired,

    imageContentType: PropType.string,
    imageBase64String: PropType.string,
    imagePath: PropType.string,

    questionText: PropType.string.isRequired
};

export default QuestionPresentation;