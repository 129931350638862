import * as actions from "../actions/types/ActionTypes";

const defaultState = {
    sortField: null,
    sortAscending: true,
    currentPage: 0,
    takeRows: 10,
    tableData: [],
    tableTotalDataCount: 0,
}

const TableReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.SET_DEFAULT_TABLE_STATE :
            return defaultState;
        case actions.SET_TABLE_SORT_PARAMETER:
            return {
                ...state,
                sortField: action.sortField,
                sortAscending: action.sortAscending
            };
        case actions.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.currentPage
            }
        case actions.SET_TAKING_ROWS:
            return {
                ...state,
                takeRows: action.takeRows,
                currentPage: 0
            };
        case actions.SET_TABLE_DATA:
            return {
                ...state,
                tableData: action.tableData,
                tableTotalDataCount: action.tableTotalDataCount
            };
        default:
            return state;
    }
}

export default TableReducer;