export const PAGE_IS_LOADING = "PAGE_LOADING";
export const PAGE_NOT_LOADING = "PAGE_NOT_LOADING";

export const SNACKBAR_ERROR_APPEARED = "SNACKBAR_ERROR_APPEARED";
export const SNACKBAR_SUCCESS_APPEARED = "SNACKBAR_SUCCESS_APPEARED";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const SET_CHALLENGE = "SET_CHALLENGE";
export const UPDATE_CHALLENGE_QUESTION = "UPDATE_CHALLENGE_QUESTION";
export const UPDATE_CHALLENGE_QUESTION_IMAGE = "UPDATE_CHALLENGE_QUESTION_IMAGE";

export const SET_DEFAULT_TABLE_STATE = "SET_DEFAULT_TABLE_STATE";
export const SET_TABLE_SORT_PARAMETER = "SET_TABLE_SORT_PARAMETER";
export const SET_CURRENT_PAGE = "SET_SKIPPING_ROWS";
export const SET_TAKING_ROWS = "SET_TAKING_ROWS";
export const SET_TABLE_DATA = "SET_TABLE_DATA";

export const SET_UPDATING_PAGE_STATUS = "SET_UPDATING_PAGE_STATUS";
export const SET_VIEW_PAGE_STATUS = "SET_VIEW_PAGE_STATUS";
export const SET_NEW_PAGE_STATUS = "SET_NEW_PAGE_STATUS";