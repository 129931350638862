import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    SetChangePage,
    SetDefaultTableState,
    SetRowsPerPage,
    SortRowsByFields
} from "../../../actions/TableActions";
import {requestHandler} from "../../../infrastructure/requestHandler";
import {USERS_MANAGEMENT_PAGE} from "../../../utils/routeNames";
import {CreateNewUser, FetchUsersTableData} from "../../../actions/UserActions";
import classes from "./UsersTable.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import PreviewTable from "../../../components/PreviewTable/PreviewTable";
import ActiveDot from "../../../components/ActiveDot/ActiveDot";
import {useHistory} from "react-router-dom";
import TableBodyContainer from "../../../components/Containers/TableBodyContainer/TableBodyContainer";
import TableHeadContainer from "../../../components/Containers/TableHeadContainer/TableHeadContainer";
import UserTableActions from "../../../components/UserTableActions/UserTableActions";
import Typography from "@mui/material/Typography";
import {TableCell} from "@mui/material";
import MainContainer from "../../../components/Containers/MainContainer/MainContainer";

const headCells = [
    {id: "Name", align: "left", label: "ФИО", sortArea: "ascending"},
    {id: "ExpireDate", align: "left", label: "Активен до", sortArea: "ascending"},
    {id: "Role", align: "left", label: "Доступ", sortArea: "ascending"},
    {id: "ProfessionsCount", align: "right", label: "Профессии", sortArea: "ascending"},
    {id: "SuccessRate", align: "right", label: "Успешность прохождений", sortArea: "ascending"},
    {id: "blocked", align: "center", label: "Заблокирован", sortArea: "ascending"},
    {id: "Action", align: "right", label: "", sortArea: "none"}
];

function prepareTableCells(rowsData, setParentState, parentState) {
    let preparedCells = [];

    rowsData.map(row => {
        preparedCells.push([
            <TableCell className={classes.nameCell}><Typography
                className={classes.textArea}>{row.name}</Typography></TableCell>,
            <TableCell className={classes.expireDateCell}><Typography
                className={classes.textArea}>{row.expireDate ? new Date(row.expireDate).toLocaleDateString() : ""}</Typography></TableCell>,
            <TableCell className={classes.roleCell}><Typography
                className={classes.textArea}>{row.role === "USER" ? "Пользователь" : "Менеджер"}</Typography></TableCell>,
            <TableCell align="right" className={classes.professionsCell}><Typography
                className={classes.textArea}>{row.professionsCount}</Typography></TableCell>,
            <TableCell align="right" className={classes.successCell}><Typography
                className={classes.textArea}>{Math.round(row.successRate)}% </Typography></TableCell>,
            <TableCell align="center" className={classes.blockedCell}><ActiveDot isActive={row.blocked}/></TableCell>,
            <TableCell align="right">{<UserTableActions setParentState={setParentState} parentState={parentState}
                                                        userId={row.id} isBlocked={row.blocked} username={row.name}/>}</TableCell>,
        ]);
    })

    return preparedCells;
}

export default function UsersTable() {

    const history = useHistory();
    const dispatch = useDispatch();
    const [stateUpdated, setUpdatingState] = useState(false);
    const tableState = useSelector(state => state.TableReduce);
    const loggedInUserState = useSelector(state => state.LoginReduce);

    useEffect(() => {
        dispatch(SetDefaultTableState());
    }, []);

    useEffect(() => {
        requestHandler(FetchUsersTableData(tableState), dispatch);
    }, [stateUpdated])

    const handleSortAction = (orderBy, isAsc) => {
        dispatch(SortRowsByFields(orderBy, isAsc));
        setUpdatingState(!stateUpdated);
    }

    const handleChangePage = (event, newPage) => {
        dispatch(SetChangePage(newPage));
        setUpdatingState(!stateUpdated);
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(SetRowsPerPage(parseInt(event.target.value, 10)));
        setUpdatingState(!stateUpdated);
    };

    const handleButtonClick = async () => {
        var result = await requestHandler(CreateNewUser(), dispatch)

        history.push(USERS_MANAGEMENT_PAGE + `/${result.id}`);
    }

    return (
        <>
            <MainContainer alignment='vertical'>
                <TableHeadContainer>
                    <div className={classes.header}>
                        <strong>{loggedInUserState.company}</strong>
                        <TableHeader buttonText="Сотрудник"
                                     handleButtonClick={handleButtonClick}/>
                    </div>
                </TableHeadContainer>
                <TableBodyContainer>
                    <PreviewTable rowsPerPage={tableState.takeRows}
                                  orderBy={tableState.sortField}
                                  isAscending={tableState.sortAscending}
                                  handleSkippingRows={handleChangePage}
                                  handleSort={handleSortAction}
                                  currentPage={tableState.currentPage}
                                  itemsCount={tableState.tableTotalDataCount}
                                  tableHeaderItems={headCells}
                                  preparedTableData={prepareTableCells(tableState.tableData, setUpdatingState, stateUpdated)}
                                  handleTakingRows={handleChangeRowsPerPage}/>
                </TableBodyContainer>
            </MainContainer>
        </>
    );
}