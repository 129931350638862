import React from 'react';
import PropType from 'prop-types';
import QuestionPresentation from "../QuestionPresentation/QuestionPresentation";
import AnswersList from "../AnswersList/AnswersList";
import classes from './ChallengeQuestion.module.css';
import QuestionExplanation from "../QuestionExplanation/QuestionExplanation";
import {useMediaQuery} from "@mui/material";

function ChallengeQuestion(props) {

    const matches = useMediaQuery('(max-width:1050px)');

    return (
        <div
            className={matches ? classes.verticalPresenting :
                props.question.imagePath ? classes.horizontalPresenting : classes.verticalPresenting}>
            <QuestionPresentation questionText={props.question.text}
                                  imagePath={props.question.imagePath}
                                  imageBase64String={props.question.imageBase64String}
                                  imageContentType={props.question.contentType}
                                  questionId={props.question.id}/>
            <div className={classes.answersArea}>
                <AnswersList isEnabled={props.allowEditing}
                             answers={props.question.answers}
                             isMultipleAnswer={props.question.isMultipleAnswer}
                             updateAnswerChoice={props.handleUpdatingAnswerChoice}/>
                {props.allowDisplayExplanation ? <div className={classes.explanationArea}>
                    <QuestionExplanation challengeId={props.challengeId}
                                         questionId={props.question.id}/>
                </div> : <></>}
            </div>
        </div>
    )
}

ChallengeQuestion.propTypes = {
    question: PropType.object.isRequired,
    allowEditing: PropType.bool.isRequired,
    handleUpdatingAnswerChoice: PropType.func.isRequired,

    allowDisplayExplanation: PropType.bool.isRequired,
    challengeId: PropType.string.isRequired
}

export default ChallengeQuestion;