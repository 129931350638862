import React from "react";
import {ShowResultSwitch} from "./styles";
import classes from "./SwitchShowResultsImmediately.module.css";

export default function SwitchShowResultsImmediately(props) {
    return (
        <div className={classes.showResultContainer}>
            <span>Показать ответы после полного прохождения</span>
            <ShowResultSwitch checked={props.showAnswersResult}
                              onChange={() => props.setShowAnswersResult(!props.showAnswersResult)}/>
            <span>Показать результат сразу после ответа</span>
        </div>
    );
}