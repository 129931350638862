import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

import MainContainer from "../Containers/MainContainer/MainContainer";
import HeadContainer from "../Containers/HeadContainer/HeadContainer";
import ManagementPageHeader from "../ManagementPageHeader/ManagementPageHeader";
import styleClasses from "./PageManager.module.css";

import {NEW_PAGE_STATUS, UPDATING_PAGE_STATUS, VIEW_PAGE_STATUS} from "../../utils/constants";

export default function PageManager(props) {
    const history = useHistory();
    const pageManageState = useSelector(state => state.PageManagementReduce);

    let leftSideButtonClickHandler = undefined;
    let rightSideButtonClickHandler = undefined;

    let leftSideButtonText = "";
    let rightSideButtonText = "";
    let rightSideButtonIcon = undefined;
    let rightSideButtonAction = "button";

    switch (pageManageState.currentStatus) {
        case NEW_PAGE_STATUS: {
            leftSideButtonClickHandler = async () => {
                await props.actionOnCancelCreation()
                history.goBack();
            }

            leftSideButtonText = "Отменить создание";
            rightSideButtonText = "Сохранить"
            rightSideButtonIcon = <SaveIcon/>;
            rightSideButtonAction = "submit";

            break;
        }
        case UPDATING_PAGE_STATUS: {
            leftSideButtonClickHandler = () => {
                props.actionOnCancelUpdate();
                rightSideButtonAction = "button"
            }

            leftSideButtonText = "Отмена";
            rightSideButtonText = "Сохранить"
            rightSideButtonIcon = <SaveIcon/>;
            rightSideButtonAction = "submit";

            break;
        }
        case VIEW_PAGE_STATUS: {
            leftSideButtonClickHandler = () => {
                history.goBack();
            }

            rightSideButtonClickHandler = () => {
                props.actionOnApplyUpdating();
            }

            leftSideButtonText = "Назад";
            rightSideButtonText = "Редактировать"
            rightSideButtonIcon = <EditIcon/>;
            rightSideButtonAction = "button";

            break;
        }
    }

    return (
        <MainContainer alignment='vertical'>
            <HeadContainer>
                <div className={styleClasses.header}>
                    <ManagementPageHeader leftSideButtonText={leftSideButtonText}
                                          handleLeftSideButtonClick={leftSideButtonClickHandler}
                                          rightSideButtonText={rightSideButtonText}
                                          rightSideButtonIcon={rightSideButtonIcon}
                                          handleRightSideButtonClick={rightSideButtonClickHandler}
                                          rightSideButtonType={rightSideButtonAction}/>
                </div>
            </HeadContainer>
            {props.children}
        </MainContainer>
    );
}

PageManager.propTypes = {
    actionOnCancelCreation: PropTypes.func,
    actionOnCancelUpdate: PropTypes.func,
    actionOnApplyUpdating: PropTypes.func,

    children: PropTypes.elementType.isRequired
}