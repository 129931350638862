import React, {useEffect, useState} from 'react';
import PropType from 'prop-types'
import classes from './FinishStats.module.css';

const invalid = 3;

const invalidPredicate = ((answer) => answer.status === invalid);

function FinishStats(props) {

    const [correctQuestionsCount, setCorrectQuestionsCount] = useState(0);
    const [correctQuestionsPercent, setCorrectQuestionsPercent] = useState(0);
    const [timeMinutesSpent, setTimeMinutesSpent] = useState(0);
    const [timeSecondsSpent, setTimeSecondsSpent] = useState(0);

    useEffect(() => {
        let correctCount = 0;
        props.questions.forEach(quest => {
            if (!quest.answers.some(invalidPredicate)) {
                correctCount++;
            }
        });

        const averagePercent = Math.round((correctCount / props.questions.length) * 100)

        const startDate = new Date(props.challengeStartTime);
        const endDate = new Date(props.challengeEndTime);
        const timeDifference = new Date(endDate - startDate);

        setCorrectQuestionsCount(correctCount);
        setCorrectQuestionsPercent(averagePercent);
        setTimeMinutesSpent(timeDifference.getUTCMinutes());
        setTimeSecondsSpent(timeDifference.getUTCSeconds());
    }, []);

    return (
        <div className={classes.resultsBlock}>
            <div className={classes.wrapper}>
                <div><strong>{timeMinutesSpent}м {timeSecondsSpent}с</strong></div>
                <div><strong>{correctQuestionsCount}/{props.questions.length} ({correctQuestionsPercent}%)</strong>
                </div>
            </div>
            {correctQuestionsPercent >= 80 ?
                <a href={"https://localhost:44389/Challenge/StartChallenge?CategoryId=D7A410AC-F0A2-40A7-BF1A-A4A3AE912F7A"}
                   className={classes.resultsLink}>
                    Отлично! Пройдите практическое тестирование для подтверждения уровня знаний</a> : <></>}
        </div>
    );
}

FinishStats.propTypes = {
    challengeEndTime: PropType.object.isRequired,
    challengeStartTime: PropType.object.isRequired,
    questions: PropType.array.isRequired
}

export default FinishStats;