import {Route, Switch, Redirect, useHistory} from "react-router-dom";
import {HOME_PAGE, LOGIN_PAGE} from "../utils/routeNames";
import LoginPage from "../pages/Login/LoginPage";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import HomePage from "../pages/Home";
import * as constants from "../utils/constants";
import {LOGIN_USER} from "../actions/types/ActionTypes";

export default function AppRouter() {

    const dispatch = useDispatch((state) => state.LoginReduce);
    // const {isAuth} = useSelector((state) => state.LoginReduce);
    const data = JSON.parse(localStorage.getItem(constants.LOCAL_AUTHORITY_KEY));
    let isAuth = data && data.accessToken && data.refreshToken;

    useEffect(() => {
        if (data && data.accessToken && data.refreshToken) {
            dispatch({
                type: LOGIN_USER,
                username: data.username,
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
                permission: data.permission,
                company: data.company
            });
        }
    }, []);

    // const user = useSelector((state) => state.LoginReduce);
    // const history = useHistory();
    //
    // useEffect(() => {
    //     if (user.isAuth) {
    //         history.push(HOME_PAGE);
    //     }
    // }, [user]);

    if (isAuth) {
        return (
            <Switch>
                <Route path={HOME_PAGE} component={HomePage} exact={false}/>
            </Switch>
        );
    } else {
        return (
            <Switch>
                <Route path={LOGIN_PAGE} component={LoginPage} exact/>
                <Redirect exact={false} from="/" push to={LOGIN_PAGE}/>
            </Switch>
        );
    }
}