export const HOME_PAGE = "/";
export const LOGIN_PAGE = "/login";

export const MENU_TAB_PAGE = "/startup";
export const MY_RESULTS_TAB_PAGE = "/my/results";
export const COMPANY_EMPLOYEES_TAB_PAGE = "/company/employees";

export const CHALLENGE_PAGE = "/challenges/:id";
export const CHALLENGE_PAGE_HISTORY = "/challenges/"

export const USERS_MANAGEMENT_PAGE = "/users/management";
export const USERS_MANAGEMENT_ROUTE_PAGE = "/users/management/:id";

export const USER_CHALLENGES = (id) => `/users/${id}/challenges`;
export const USER_CHALLENGES_PAGE = "/users/:id/challenges";