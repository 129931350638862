export const login = () => "/login";

export const refreshToken = () =>  "/token/refresh";
export const checkTokenAlive = () =>  "/token/checkAlive";

export const getMyProfessions = () => "/my/professions";
export const checkMyChallengeExists = () => "/my/challenge";
export const myChallenges = () => "/my/challengesList";

export const createChallenge = () => "/challenges";
export const cancelChallenge = (id) => `/challenges/${id}/cancel`;
export const getChallenge = (id) => `/challenges/${id}`;
export const checkInQuestion = (id) => `/challenges/${id}/checkIn`;
export const finishChallenge = (id) => `/challenges/${id}/finish`;
export const questionExplanation = (id, questionId) => `/challenges/${id}/questions/${questionId}/explanation`;

export const get_image = (fileName) => `/image/${fileName}`;

export const users_list = () => "/users";
export const get_user = (id) => `/users/${id}`;
export const update_user = (id) => `/users/${id}`;
export const new_user = () => `/users`;
export const update_user_activity = (id) => `/users/${id}/activity`;
export const update_user_credentials = (id) => `/users/${id}/changePassword`;
export const update_user_professions = (id) => `/users/${id}/professions`;
export const users_challenges = (id) => `/users/${id}/challenges`;
export const deleteUser = (id) => `/users/${id}`;

export const getCompanyProfessions = () => "/company/professions";
