import React, {useState} from 'react';

import welcomeLogo from "../../static/welcomelogo.png";
import classes from "./LoginPage.module.css";
import {LoginInputTextField, LoginButton} from "./styles";
import {useHistory} from "react-router-dom";
import {MENU_TAB_PAGE} from "../../utils/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {requestHandler} from "../../infrastructure/requestHandler";
import {LoginUser} from "../../actions/LoginActions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

export default function LoginPage() {

    const [formValue, setFormValue] = useState({
        Login: "",
        Password: "",
    });

    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.LoadingReduce);

    const clickLoginHandler = async () => {
        const loggedIn = await requestHandler(LoginUser({
            Login: formValue.Login,
            Password: formValue.Password
        }), dispatch);

        if (loggedIn) {
            history.push(MENU_TAB_PAGE);
        }
    };

    const textFormHandler = (e) => {
        setFormValue({...formValue, [e.target.name]: e.target.value});
    };

    return (
        <div className={classes.root}>
            {loading.isLoading && <LoadingSpinner/>}
            <div className={classes.leftsideArea}>
                <div className={classes.welcomePlacement}>
                    <img src={welcomeLogo}
                         className={classes.welcomeLogo}/>
                    <h2 className={classes.welcomeLabel}>Охрана труда в Беларуси</h2>
                </div>
            </div>
            <div className={classes.rightsideRoot}>
                <div className={classes.rightsideArea}>
                    <div className={classes.loginArea}>
                        <h3 className={classes.loginLabel}>
                            Добро пожаловать на OTests Portal
                        </h3>

                        <LoginInputTextField
                            label="Логин"
                            name="Login"
                            onChange={textFormHandler}
                            autoComplete="off"/>
                        <LoginInputTextField
                            name="Password"
                            label="Пароль"
                            onChange={textFormHandler}
                            type="password"
                            autoComplete="off"/>

                        <LoginButton onClick={clickLoginHandler}>
                            Войти
                        </LoginButton>
                    </div>
                </div>
            </div>
        </div>);
}