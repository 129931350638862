import * as action from "./types/ActionTypes";
import * as constants from "../utils/constants";

import api from "../infrastructure/Api";

export const TryRestoreToken = () => async (dispatch) => {
    let alive = false;
    try {
        const data = JSON.parse(localStorage.getItem(constants.LOCAL_AUTHORITY_KEY));
        if (data && data.accessToken && data.refreshToken) {
            const checkResult = await api.checkAlive();
            alive = checkResult.alive;

            if (!checkResult.alive) {
                const updatedCredentials = await api.refreshToken({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken
                });

                data.accessToken = updatedCredentials.accessToken;
                data.refreshToken = updatedCredentials.refreshToken;

                localStorage.setItem(
                    constants.LOCAL_AUTHORITY_KEY,
                    JSON.stringify(data)
                );

                dispatch({
                    type: action.REFRESH_TOKEN,
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken
                });

                alive = true;
            }
        }
    } catch {
        alive = false;

        localStorage.removeItem(constants.LOCAL_AUTHORITY_KEY);

        dispatch({
            type: action.LOGOUT_USER,
        });
    }
    return alive;
}
