import React from 'react';
import styleClasses from "./LoadingSpinner.module.css";

export default function LoadingSpinner(props) {
    return (
        <React.Fragment>
            <div className={`${styleClasses.loadBar} ${styleClasses.stripped} ${styleClasses.loadBarAnimation}`} />
            <div className={styleClasses.loadingBackground} />
        </React.Fragment>
    );
}