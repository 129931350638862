import {Alert} from "@mui/material";
import {useEffect, useState} from "react";

const examinationText = "Данное тестирование приближенно к реальному процессу прохождения проверки знаний. По итогам будут предоставлены только конечный результат, без объяснений ошибок";

const complexityDefines = {
    1: {
        definedText: 'легкие вопросы',
        alertSeverity: 'success'
    },
    2: {
        definedText: 'вопросы средней сложности',
        alertSeverity: 'warning'
    },
    3: {
        definedText: 'вопросы самой высокой сложности',
        alertSeverity: 'error'
    }
}

const compilationDefines = {
    1: 'ваших неправильных ответах',
    2: 'неправильных ответах всех пользователей'
}

export default function AlertLabel(props) {

    const [alertSeverity, setSeverity] = useState('info');
    const [complexityAlertText, setComplexityAlertText] = useState('');
    const [compilationAlertText, setCompilationAlertText] = useState('');

    useEffect(() => {
        const complexity = complexityDefines[props.challengeComplexity];
        const compilation = compilationDefines[props.challengeCompilation];

        setComplexityAlertText(complexity.definedText);
        setSeverity(complexity.alertSeverity);

        setCompilationAlertText(compilation);
    }, [props.challengeComplexity, props.challengeCompilation])

    return (
        <>
            {props.isExam ?
                <Alert severity='info'>
                    {examinationText}
                </Alert> :
                <Alert severity={alertSeverity}>
                    В тесте будут подобраны <strong>{complexityAlertText}</strong>, основываясь
                    на <strong>{compilationAlertText}</strong>
                </Alert>
            }
        </>
    );
}