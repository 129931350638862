import * as actions from "../actions/types/ActionTypes";

const defaultAnswer = {
    id: "",
    text: "",
    status: 0,
    isPicked: false
}

const defaultQuestion = {
    id: "",
    text: "",
    imagePath: "",
    imageBase64String: "",
    contentType: "",
    isMultipleAnswer: false,
    answers: []
}

const defaultState = {
    id: "",
    questions: [],
    startDateTime: undefined,
    endDateTime: undefined,
    duration: 0,
    status: 0,
    showResult: false
};

const ChallengeReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.SET_CHALLENGE: {
            const questions = action.challenge.questions.map(quest => {
                return {
                    ...defaultQuestion,
                    id: quest.id,
                    text: quest.text,
                    imagePath: quest.imagePath,
                    isMultipleAnswer: quest.isMultipleAnswer,
                    answers: quest.answers
                }
            })

            return {
                ...state,
                id: action.challenge.id,
                questions: questions,
                startDateTime: action.challenge.startDateTime,
                endDateTime: action.challenge.endDateTime,
                duration: action.challenge.duration,
                status: action.challenge.status,
                showResult: action.challenge.showResult
            }
        }
        case actions.UPDATE_CHALLENGE_QUESTION: {
            state.questions.forEach(q => {
                if(q.id === action.questionId) {
                    q.answers = action.answers;
                }
            });

            return state;
        }
        case actions.UPDATE_CHALLENGE_QUESTION_IMAGE: {
            state.questions.forEach(q => {
                if(q.id === action.questionId) {
                    q.imageBase64String = action.imageBase64String;
                    q.contentType = action.contentType;
                }
            });

            return state;
        }
        default:
            return state;
    }
}

export default ChallengeReducer;