import React from 'react';
import PropTypes from "prop-types";
import TimelineProgressBar from "../TimelineProgressBar/TimelineProgressBar";
import classes from './ChallengeProgressBar.module.css';

function ChallengeProgressBar(props) {
    return (
        <div className={classes.progressContainer}>
            <span className={classes.questionLabel}>ВОПРОС {props.currentQuestionNumber}</span>
            {props.allowStart ? <TimelineProgressBar {...props}/> : <></>}
        </div>
    );
}

ChallengeProgressBar.propTypes = {
    currentQuestionNumber: PropTypes.number.isRequired,

    startDate: PropTypes.object.isRequired,
    duration: PropTypes.number.isRequired,

    allowStart: PropTypes.bool.isRequired,
    endTimeCallback: PropTypes.func.isRequired
}

export default ChallengeProgressBar;