import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import routes from "./routes";
import * as constants from "../../utils/constants";
import classes from './index.module.css';
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import {useMediaQuery} from "@mui/material";
import {MENU_TAB_PAGE} from "../../utils/routeNames";

export default function HomePage() {

    const [value, setValue] = useState(0);

    const matches = useMediaQuery('(max-width:1050px)');
    const location = useLocation();

    const authorities = JSON.parse(localStorage.getItem(constants.LOCAL_AUTHORITY_KEY));

    useEffect(() => {
        const route = routes.find(r => r.path === location.pathname);
        if (route !== undefined) {
            setValue(route.tabIndicator);
        }
    }, [location.pathname])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.layoutBackground}>
            {matches ? <MobileHeader value={value} handleChange={handleChange} routes={routes}/> :
                <Header value={value} handleChange={handleChange} routes={routes}/>}
            <Switch>
                {routes.map((route) => {
                        if (route.acceptedRole.includes(authorities.permission))
                            return <Route component={route.component}
                                          path={route.path}
                                          exact={route.isExact}/>;
                    }
                )}
                <Redirect exact={false} from="/" push to={MENU_TAB_PAGE} />
            </Switch>
        </div>
    );
}