import {combineReducers} from "redux";

import LoginReducer from "./LoginReducer";
import SnackbarReducer from "./SnackbarReducer";
import LoadingReducer from "./LoadingReducer";
import ChallengeReducer from "./ChallengeReducer";
import TableReducer from "./TableReducer";
import PageManagementReducer from "./PageManagementReducer";

const RootReducer = combineReducers({
    LoadingReduce: LoadingReducer,
    LoginReduce: LoginReducer,
    SnackbarReduce: SnackbarReducer,
    ChallengeReduce: ChallengeReducer,
    TableReduce: TableReducer,
    PageManagementReduce: PageManagementReducer
});

export default RootReducer;