import styled from "@emotion/styled";
import {FormControl} from "@mui/material";

export const SelectionFormControl = styled(FormControl)(
    ({theme}) => ({
        width: "100%",
        marginBottom: "20px",
        '&:clicked': {
            'border-color': "var(--color-red)"
        }
    })
);