import PageManager from "../../../components/PageManager/PageManager";
import styleClasses from "./UserManagementPage.module.css";
import React, {useEffect, useState} from "react";
import {requestHandler} from "../../../infrastructure/requestHandler";
import {
    DeleteUser,
    GetUserData,
    UpdateUser,
    UpdateUserCredentials,
    UpdateUserData, UpdateUserProfessions,
    ViewUser
} from "../../../actions/UserActions";
import {useDispatch, useSelector} from "react-redux";
import {VIEW_PAGE_STATUS} from "../../../utils/constants";
import * as yup from "yup";
import {useFormik} from "formik";
import FormSelectionElement from "../../../components/FormSelectionElement/FormSelectionElement";
import FormTextareaElement from "../../../components/FormTextareaElement/FormTextareaElement";
import {SelectionFormControl} from "./styles";
import PasswordChangeModal from "../../../components/PasswordChangeModal/PasswordChangeModal";
import ProfessionsPickerModal from "../../../components/ProfessionsPickerModal/ProfessionsPickerModal";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const rolesList = [
    {
        key: "OBSERVER",
        value: "Менеджер"
    },
    {
        key: "USER",
        value: "Пользователь"
    }];

export default function UserManagementPage(props) {

    const userId = props.match.params.id;
    const dispatch = useDispatch();

    const pageManageState = useSelector(state => state.PageManagementReduce);
    const loading = useSelector(state => state.LoadingReduce);

    const [isEditingDisabled, disableEditing] = useState(pageManageState.currentStatus === VIEW_PAGE_STATUS);
    const [user, setUser] = useState(undefined);
    const [credentialsModalState, updateCredentialsModalState] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            let user = await requestHandler(GetUserData(userId), dispatch);
            setUser(user);
        }

        fetchUserData();
    }, []);

    useEffect(() => {
        disableEditing(pageManageState.currentStatus === VIEW_PAGE_STATUS);
    }, [pageManageState.currentStatus]);

    const saveUser = async (values) => {
        const {name, email, role, company} = values;
        await requestHandler(UpdateUserData(userId, name, email, role, company), dispatch);
    }

    const validationSchema = yup.object({
        role: yup.string("Choose user's role").required('Role choice is required').nullable(),
        name: yup.string("Enter user's name").required("User's name is required").nullable(),
        email: yup.string("Enter user's email").email("Email typed in incorrect format").nullable()
    });

    const formik = useFormik({
        onSubmit: saveUser,
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: user === undefined ? null : user.name,
            email: user === undefined ? null : user.email,
            role: user === undefined ? null : user.role,
            company: user === undefined ? null : user.company === null ? null : user.company.id,
        },
    });

    const updateUserCredentialsHandler = async (id, login, password) => {
        const updatedLogin = await requestHandler(UpdateUserCredentials(id, login, password), dispatch);
        setUser({
            ...user,
            login: updatedLogin
        });
    }

    const actionOnCancelCreation = async () => {
        await requestHandler(DeleteUser(userId), dispatch);
    }

    const actionOnCancelUpdate = () => {
        dispatch(ViewUser());
        disableEditing(true);
    }

    const actionOnApplyUpdating = () => {
        disableEditing(false);
        dispatch(UpdateUser());
    }

    const handleSavingUserProfessions = async (userProfessions) => {
        const professionsIds = userProfessions.map((prof) => {
            return prof.id
        });

        const updatedProfessionsList = await requestHandler(UpdateUserProfessions(user.id, professionsIds), dispatch);
        setUser({
            ...user,
            professions: updatedProfessionsList
        });
    }

    const showingForm = (
        <PageManager actionOnCancelCreation={actionOnCancelCreation}
                     actionOnCancelUpdate={actionOnCancelUpdate}
                     actionOnApplyUpdating={actionOnApplyUpdating}>
            <div className={styleClasses.formArea}>
                <div className={styleClasses.dependentArea}>

                    <div>
                        <FormTextareaElement onChangeHandler={formik.handleChange}
                                             touchedElement={formik.touched.name}
                                             errorElement={formik.errors.name}
                                             value={formik.values.name}
                                             disabled={isEditingDisabled}
                                             minRows={1}
                                             label="ФИО"
                                             name="name"/>
                    </div>
                    <div>
                        <FormTextareaElement onChangeHandler={formik.handleChange}
                                             touchedElement={formik.touched.email}
                                             errorElement={formik.errors.email}
                                             value={formik.values.email}
                                             disabled={isEditingDisabled}
                                             minRows={1}
                                             label="E-Mail"
                                             name="email"/>
                    </div>
                    <div>
                        <SelectionFormControl variant="standard">
                            <FormSelectionElement
                                name="role"
                                value={formik.values.role}
                                errorElement={formik.errors.role}
                                touchedElement={formik.touched.role}
                                label="Доступ"
                                selectionList={rolesList}
                                onChangeHandler={formik.handleChange}
                                disabled={isEditingDisabled}/>
                        </SelectionFormControl>
                    </div>
                </div>
                <div className={styleClasses.dependentArea}>
                    <PasswordChangeModal userId={userId}
                                         login={user ? user.login ?? "" : ""}
                                         isModalOpened={credentialsModalState}
                                         openModal={updateCredentialsModalState}
                                         updateUserCredentialsHandler={updateUserCredentialsHandler}/>
                </div>
                <div className={styleClasses.dependentArea}>
                    <ProfessionsPickerModal userProfessions={user ? user.professions : []}
                                            saveProfessionsListHandler={handleSavingUserProfessions}/>
                </div>
            </div>
        </PageManager>
    );

    if (isEditingDisabled) {
        return (
            <>
                {loading.isLoading && <LoadingSpinner/>}
                {showingForm}
            </>
        )
    } else {
        return (
            <form onSubmit={formik.handleSubmit}>
                {loading.isLoading && <LoadingSpinner/>}
                {showingForm}
            </form>
        );
    }
}