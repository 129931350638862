import React from 'react';
import ReactDOM from "react-dom";
import './index.css';
import App from './App/App';
import {BrowserRouter as Router} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Store from "./Store";
import { Provider } from "react-redux";

ReactDOM.render(
    <Provider store={Store}>
        <Router>
            <SnackbarProvider maxSnack={3}>
                <App />
            </SnackbarProvider>
        </Router>
    </Provider>,
    document.getElementById("root")
);