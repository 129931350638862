import React from "react";
import AddIcon from '@mui/icons-material/Add';
import PropTypes from "prop-types";
import {ElementButton} from "./styles";

export default function TableHeader(props) {
    return (
        <div>
            <ElementButton startIcon={<AddIcon/>}
                           onClick={() => props.handleButtonClick()}>
                {props.buttonText}</ElementButton>
        </div>
    );
}

TableHeader.propTypes = {
    buttonText: PropTypes.string.isRequired,
    handleButtonClick: PropTypes.func.isRequired
}