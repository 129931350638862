import {
    SET_CURRENT_PAGE,
    SET_DEFAULT_TABLE_STATE,
    SET_TABLE_SORT_PARAMETER,
    SET_TAKING_ROWS
} from './types/ActionTypes'

export const SetDefaultTableState = () => async dispatch => {
    dispatch({
        type: SET_DEFAULT_TABLE_STATE
    });
}

export const SetChangePage = (newPage) => async dispatch => {
    dispatch({
        type: SET_CURRENT_PAGE,
        currentPage: newPage
    });
}

export const SetRowsPerPage = (takeRows) => async dispatch => {
    dispatch({
        type: SET_TAKING_ROWS,
        takeRows: takeRows
    })
}

export const SortRowsByFields = (sortField, sortAscending) => async dispatch => {
    dispatch({
        type: SET_TABLE_SORT_PARAMETER,
        sortField: sortField,
        sortAscending: sortAscending
    });
}