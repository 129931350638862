import { styled } from '@mui/material/styles';
import {Button, TextField} from "@mui/material";

export const LoginInputTextField = styled(TextField)({
    marginTop : "25px",
    marginLeft : '6%',
    marginRight : '6%',
    '& label.Mui-focused': {
        color: 'var(--color-yellow)',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'var(--color-yellow)',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
            borderBottomColor: 'var(--color-yellow)',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
            borderBottomColor: 'var(--color-yellow-hover)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
            borderBottomColor: 'var(--color-yellow)',
        },
    },
});

export const LoginButton = styled(Button)(({ theme }) => ({
    color: "white",
    marginLeft : '6%',
    marginRight : '6%',
    marginTop: 'auto',
    marginBottom: '20px',
    backgroundColor: "var(--color-yellow)",
    '&:hover': {
        backgroundColor: "var(--color-yellow-hover)"
    },
    ['@media (max-height:440px)']: {
        marginTop: '30px',
    }
}));