export const paperSx = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '4px',
    margin: 0
};

export const chipSx = {
    margin: '4px',
};