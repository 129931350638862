import CircleIcon from '@mui/icons-material/Circle';
import {styled} from "@mui/material/styles";
import {Rating} from "@mui/material";

export const StyledRating = styled((props) => (
    <Rating {...props}/>
))({
    '& .MuiRating-iconFilled': {
        border: '2px solid lightgray',
        borderRadius: '50%',
        color: 'inherit'
    },
});

export const GreenCircle = styled((props) => (
    <CircleIcon {...props}/>
))({
    fontSize: '48px',
    color: 'var(--success-primary)'
});

export const YellowCircle = styled((props) => (
    <CircleIcon {...props}/>
))({
    fontSize: '48px',
    color: 'var(--color-yellow)'
});

export const RedCircle = styled((props) => (
    <CircleIcon {...props}/>
))({
    fontSize: '48px',
    color: 'var(--color-red)'
});