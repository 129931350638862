import * as axios from "axios";
import * as constants from "../utils/constants";
import * as apiRoutes from "../utils/apiRoutes";

const formDataType = "multipart/form-data";

class Api {
    constructor() {
        this.client = null;
        this.api_url = `${process.env.REACT_APP_BACKEND_API_URL}`;
    }


    init = (content_type) => {
        let headers = {
            "Content-Type": content_type,
        };

        const userData = localStorage.getItem(constants.LOCAL_AUTHORITY_KEY);
        if (userData) {
            const parsedValues = JSON.parse(userData);
            headers.authorization = `Bearer ${parsedValues.accessToken}`;
            headers["Refresh-Token"] = parsedValues.refreshToken;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 30000,
            headers: headers,
        });

        return this.client;
    };

    // region Users
    loginUser = async ({Login, Password}) => {
        const formData = new FormData();
        formData.append("Login", Login);
        formData.append("Password", Password);

        return (await this.init(formDataType)
            .post(apiRoutes.login(), formData)).data;
    };
    // endregion

    // region Tokens
    refreshToken = async ({accessToken, refreshToken}) => {
        const formData = new FormData();
        formData.append("AccessToken", accessToken);
        formData.append("RefreshToken", refreshToken);

        return (await this.init(formDataType)
            .post(apiRoutes.refreshToken(), formData)).data;
    }

    checkAlive = async () => {
        return (await this.init(formDataType)
            .post(apiRoutes.checkTokenAlive())).data;
    }
    // endregion

    // region MyActions
    getMyProfessions = async () => {
        return (await this.init()
            .get(apiRoutes.getMyProfessions())).data;
    }

    checkMyChallengeExists = async () => {
        return (await this.init()
            .get(apiRoutes.checkMyChallengeExists())).data;
    }

    getMyChallenges = async ({SortField, IsAscendingSortOrder, KeepRecords, SkipRecords}) => {
        return (await this.init("application/json")
            .get(apiRoutes.myChallenges(), {
                params: {
                    SortField,
                    IsAscendingSortOrder,
                    KeepRecords,
                    SkipRecords,
                },
            })).data;
    }
    // endregion

    // region Challenges
    createChallenge = async ({
                                 challengeType,
                                 questionsQuantity,
                                 challengeComplexity,
                                 compilation,
                                 topicIds,
                                 showResult
                             }) => {
        const formData = new FormData();
        formData.append("ChallengeType", challengeType);
        formData.append("QuestionsQuantity", questionsQuantity);
        formData.append("ChallengeComplexity", challengeComplexity);
        formData.append("Compilation", compilation);
        topicIds.map((id, index) => {
            formData.append(`TopicIds[${index}]`, id);
        });
        formData.append("ShowResult", showResult);

        return (await this.init(formDataType)
            .post(apiRoutes.createChallenge(), formData)).data;
    }

    cancelChallenge = async (id) => {
        return (await this.init(formDataType)
            .post(apiRoutes.cancelChallenge(id))).data;
    }

    getChallenge = async (id) => {
        return (await this.init(formDataType)
            .get(apiRoutes.getChallenge(id))).data;
    }

    checkIn = async ({challengeId, questionId, answerChoices}) => {
        const formData = new FormData();
        formData.append("QuestionId", questionId);
        answerChoices.forEach((answer, index) => {
            formData.append(`AnswerChoices[${index}]`, answer);
        });

        return (await this.init(formDataType)
            .put(apiRoutes.checkInQuestion(challengeId), formData)).data;
    }

    finish = async (id) => {
        return (await this.init()
            .post(apiRoutes.finishChallenge(id))).data;
    }

    getQuestionExplanation = async (id, questionId) => {
        return (await this.init()
            .get(apiRoutes.questionExplanation(id, questionId))).data;
    }
    // endregion

    // region Image
    getImage = async ({fileName}) => {
        return (await this.init()
            .get(apiRoutes.get_image(fileName))).data;
    };
    // endregion

    // region Users

    getUsers = async ({SortField, IsAscendingSortOrder, KeepRecords, SkipRecords}) => {
        return (await this.init("application/json")
            .get(apiRoutes.users_list(), {
                params: {
                    SortField,
                    IsAscendingSortOrder,
                    KeepRecords,
                    SkipRecords,
                },
            })).data;
    };

    createUser = async () => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_user())).data;
    };

    getUser = async (id) => {
        return (await this.init()
            .get(apiRoutes.get_user(id))).data;
    };

    updateUserActivity = async ({id, userFree}) => {
        const data = new FormData();

        data.append('UserFree', userFree);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_user_activity(id), data)).data;
    }

    updateUser = async ({id, name, email, role, companyId}) => {
        const data = new FormData();

        data.append('Name', name);
        data.append('Email', email ?? "");
        data.append('Role', role);
        data.append('CompanyId', companyId ?? "");

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_user(id), data)).data;
    }

    updateUserCredentials = async ({id, login, password}) => {
        const data = new FormData();

        data.append('Login', login);
        data.append('Password', password);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_user_credentials(id), data)).data;
    }

    getUserChallenges = async (userId, {SortField, IsAscendingSortOrder, KeepRecords, SkipRecords}) => {
        return (await this.init()
            .get(apiRoutes.users_challenges(userId), {
                params: {
                    SortField,
                    IsAscendingSortOrder,
                    KeepRecords,
                    SkipRecords,
                },
            })).data;
    };


    updateUserProfessions = async ({id, professionsList}) => {
        const data = new FormData();

        professionsList.forEach(profId => {
            data.append('Professions', profId);
        })

        return (await this.init(formDataType)
            .put(apiRoutes.update_user_professions(id), data)).data;
    }

    deleteUser = async (id) => {
        return (await this.init(formDataType)
            .delete(apiRoutes.deleteUser(id))).data;
    };
    // endregion

    // region Company
    getCompanyProfessions = async () => {
        return (await this.init()
            .get(apiRoutes.getCompanyProfessions())).data;
    }

    // endregion
}

export default new Api();