import PropTypes from "prop-types";
import TableAction from "../TableAction/TableAction";
import {useHistory} from "react-router";
import {USER_CHALLENGES, USERS_MANAGEMENT_PAGE} from "../../utils/routeNames";
import {useDispatch} from "react-redux";
import {requestHandler} from "../../infrastructure/requestHandler";
import {useState} from "react";
import {ChangeUserActivityStatus, UpdateUser, ViewUser} from "../../actions/UserActions";

function fillAction(key, label, handleClickEvent) {
    return {key, label, handleClickEvent};
}

function UserTableActions(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const [anchor, setAnchorEl] = useState(false);

    const handleUserViewAction = (id) => {
        dispatch(ViewUser());
        history.push(USERS_MANAGEMENT_PAGE + `/${id}`);
    }

    const handleUserEditAction = (id) => {
        dispatch(UpdateUser());
        history.push(USERS_MANAGEMENT_PAGE + `/${id}`);
    }

    const handleUserActivityChangeAction = async (id) => {
        await requestHandler(ChangeUserActivityStatus(id, !props.isBlocked), dispatch);
        props.setParentState(!props.parentState);
        setAnchorEl(null);
    }

    const handleUserViewStatisticsAction = (id) => {
        history.push(USER_CHALLENGES(id), {username: props.username});
    }

    let userActions = [];

    if (props.isBlocked === true) {
        userActions.push(fillAction("changeActivity", "Разблокировать", () => handleUserActivityChangeAction(props.userId)));
    } else {
        userActions.push(fillAction("view", "Просмотреть", () => handleUserViewAction(props.userId)));
        userActions.push(fillAction("edit", "Редактировать", () => handleUserEditAction(props.userId)));
        userActions.push(fillAction("statistics", "Статистика", () => handleUserViewStatisticsAction(props.userId)));
        userActions.push(fillAction("changeActivity", "Заблокировать", () => handleUserActivityChangeAction(props.userId)));
    }

    return (
        <TableAction anchor={anchor}
                     setAnchorEl={setAnchorEl}
                     actionItems={userActions}/>
    )
}

UserTableActions.propTypes = {
    userId: PropTypes.string.isRequired,
    isBlocked: PropTypes.bool.isRequired,
    setParentState: PropTypes.func,
    parentState: PropTypes.bool
}

export default UserTableActions;