import {styled} from '@mui/material/styles';
import {Button, Switch} from "@mui/material";

export const ChallengeFrameSwitch = styled(Switch)(({theme}) => ({
    '& .MuiSwitch-switchBase': {
        color: '#7fafe2',
        '&:hover': {
            backgroundColor: '#35505f40',
        },
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#ca4a53',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#685f86',
        '&:hover': {
            backgroundColor: '#4c446040',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#ca4a53',
    },
}));

export const StartChallengeButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    backgroundColor: 'var(--success-primary)',
    width: '100%',
    marginTop: '40px',
    '&:hover': {
        backgroundColor: 'var(--success-hover)',
    },
    '&:disabled': {
        backgroundColor: 'var(--success-primary-transparent)'
    }
}));