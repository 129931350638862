import { styled } from '@mui/material/styles';
import {Switch} from "@mui/material";

export const CompilationSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        color: 'var(--color-yellow)',
        '&:hover': {
            backgroundColor: 'var(--color-yellow-transparent)',
        },
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#ca4a53',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: 'var(--success-primary)',
        '&:hover': {
            backgroundColor: 'var(--success-primary-transparent)',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#ca4a53',
    },
}));