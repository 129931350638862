import {useState} from "react";
import {useHistory} from "react-router";
import PropTypes from "prop-types";

import {CHALLENGE_PAGE_HISTORY} from "../../utils/routeNames";
import TableAction from "../TableAction/TableAction";

function fillAction(key, label, handleClickEvent) {
    return {key, label, handleClickEvent};
}

function ChallengeTableActions(props) {

    const history = useHistory();

    const [anchor, setAnchorEl] = useState(false);

    const handleChallengeViewAction = (id) => {
        history.push(CHALLENGE_PAGE_HISTORY + id);
    }

    let userActions = [
        fillAction("view", "Просмотреть", () => handleChallengeViewAction(props.challengeId))
    ];

    return (
        <TableAction anchor={anchor}
                     setAnchorEl={setAnchorEl}
                     actionItems={userActions}/>
    )
}

ChallengeTableActions.propTypes = {
    challengeId: PropTypes.string.isRequired,
    setParentState: PropTypes.func,
    parentState: PropTypes.bool
}

export default ChallengeTableActions;