import * as actions from "../actions/types/ActionTypes";
import {
    NEW_PAGE_STATUS,
    UPDATING_PAGE_STATUS,
    VIEW_PAGE_STATUS
} from "../utils/constants"

const defaultState = {
    currentStatus: VIEW_PAGE_STATUS,
}

const PageManagementReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.SET_NEW_PAGE_STATUS : {
            return {
                currentStatus: NEW_PAGE_STATUS
            };
        }
        case actions.SET_UPDATING_PAGE_STATUS : {
            return {
                currentStatus: UPDATING_PAGE_STATUS
            };
        }
        case actions.SET_VIEW_PAGE_STATUS : {
            return {
                currentStatus: VIEW_PAGE_STATUS
            };
        }
        default:
            return state;
    }
}

export default PageManagementReducer;