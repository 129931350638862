import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import QuestionsCountPicker from "../QuestionsCountPicker/QuestionsCountPicker";
import classes from './TrainingChallengeFrame.module.css';
import ComplexityPicker from "../ComplexityPicker/ComplexityPicker";
import CompilationPicker from "../CompilationPicker/CompilationPicker";
import AlertLabel from "../AlertLabel/AlertLabel";
import SwitchShowResultsImmediately from "../SwitchShowResultsImmediately/SwitchShowResultsImmediately";

function TrainingChallengeFrame(props) {

    useEffect(() => {
        if (!props.examSelected) {
            props.setChallengeCompilation(2);
            props.setChallengeComplexity(2);
            props.setQuestionsCount(0);
            props.setShowAnswersResult(false);
        }
    }, [props.examSelected]);

    return (
        <React.Fragment>
            <div className={classes.questionsPicker}>
                <QuestionsCountPicker value={props.questionsCount}
                                      setValue={props.setQuestionsCount}
                                      totalQuestionsCount={props.totalQuestionsCount}
                                      showQuestionsPickingLine={true}/>
            </div>
            <div className={classes.showResultArea}>
                <SwitchShowResultsImmediately showAnswersResult={props.showAnswersResult}
                                              setShowAnswersResult={props.setShowAnswersResult}/>
            </div>
        </React.Fragment>
    )
}

TrainingChallengeFrame.propTypes = {
    totalQuestionsCount: PropTypes.number.isRequired,
    questionsCount: PropTypes.number.isRequired,
    setQuestionsCount: PropTypes.func.isRequired,

    challengeComplexity: PropTypes.number.isRequired,
    setChallengeComplexity: PropTypes.func.isRequired,

    challengeCompilation: PropTypes.number.isRequired,
    setChallengeCompilation: PropTypes.func.isRequired,

    showAnswersResult: PropTypes.bool.isRequired,
    setShowAnswersResult: PropTypes.func.isRequired,
}

export default TrainingChallengeFrame;