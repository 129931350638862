import * as React from 'react';
import {styled, alpha} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classes from "./MobileHeader.module.css";
import logo from "../../static/mainlogo.png";
import {AppBar, Toolbar} from "@mui/material";
import * as constants from "../../utils/constants";
import {requestHandler} from "../../infrastructure/requestHandler";
import {LogoutUser} from "../../actions/LoginActions";
import {LOGIN_PAGE} from "../../utils/routeNames";
import LogoutIcon from "@mui/icons-material/Logout";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {HeaderTab} from "../Header/styles";
import {MobileToolbar, StyledMenu} from "./styles";

export default function MobileHeader(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const history = useHistory();
    const dispatch = useDispatch();

    const authorities = JSON.parse(localStorage.getItem(constants.LOCAL_AUTHORITY_KEY));

    const logoutUser = async () => {
        await requestHandler(LogoutUser(), dispatch);
        history.push(LOGIN_PAGE);

        setAnchorEl(null);
    }

    const handleChange = (routeUrl) => {
        history.push(routeUrl)
        setAnchorEl(null);
    }

    return (
        <AppBar position="static" className={classes.appBar}>
            <MobileToolbar>
                <div className={classes.centeredLabel}>
                    <img className={classes.logoImg} src={logo} alt="Логотип"/>
                    <h3 className={classes.logoLabel}>TESTS PORTAL</h3>
                </div>
                <div>
                    <Button
                        id="demo-customized-button"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        sx={{
                            fontSize: '12px',
                            marginRight: '10px',
                            backgroundColor: 'var(--color-red)',
                            '&:hover': {
                                backgroundColor: 'var(--color-red-hover)',
                            }
                        }}
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon/>}>
                        {authorities.username}
                    </Button>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}>
                        {props.routes.map(route => {
                            if (route.tabIndicator !== -1 && route.acceptedRole.includes(authorities.permission)) {
                                return <MenuItem onClick={() => handleChange(route.path)}>
                                    {route.headerLabel}
                                </MenuItem>
                            }
                        })}
                        <Divider sx={{my: 0.5}}/>
                        <MenuItem onClick={logoutUser} disableRipple>
                            <LogoutIcon/>
                            Выйти
                        </MenuItem>
                    </StyledMenu>
                </div>
            </MobileToolbar>
        </AppBar>
    );
}