import PropTypes from "prop-types";
import React from "react";
import classes from "./PreviewTable.module.css";
import PreviewTableHeader from "./PreviewTableHeader/PreviewTableHeader";
import PreviewTableBody from "./PreviewTableBody/PreviewTableBody";
import {Paper, Table, TableContainer, TablePagination} from "@mui/material";
import {FixedTableContainer} from "./styles";

function PreviewTable(props) {
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <FixedTableContainer>
                    <Table className={classes.table}
                           aria-labelledby="tableTitle"
                           size="small"
                           aria-label="enhanced table">
                        <PreviewTableHeader
                            handleSort={props.handleSort}
                            headElements={props.tableHeaderItems}
                            isAscending={props.isAscending}
                            orderBy={props.orderBy}/>
                        <PreviewTableBody
                            handleTakingRows={props.handleTakingRows}
                            itemsCount={props.itemsCount}
                            currentPage={props.currentPage}
                            handleSkippingRows={props.handleSkippingRows}
                            rowsPerPage={props.rowsPerPage}
                            preparedTableData={props.preparedTableData}/>
                    </Table>
                </FixedTableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={props.itemsCount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.currentPage}
                    onPageChange={props.handleSkippingRows}
                    onRowsPerPageChange={props.handleTakingRows}
                />
            </Paper>
        </div>
    );
}

PreviewTable.propTypes = {
    currentPage: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    itemsCount: PropTypes.number.isRequired,

    tableHeaderItems: PropTypes.array.isRequired,
    preparedTableData: PropTypes.array.isRequired,
    orderBy: PropTypes.string,
    isAscending: PropTypes.bool.isRequired,

    handleSort: PropTypes.func.isRequired,
    handleTakingRows: PropTypes.func.isRequired,
    handleSkippingRows: PropTypes.func.isRequired,
}

export default PreviewTable;