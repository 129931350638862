import React from 'react';
import PropType from 'prop-types';
import {Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup} from "@mui/material";
import {CorrectCheckbox, CorrectRadio, InvalidCheckbox, InvalidRadio} from "./styles";

const radioSx = {
    '.MuiFormControlLabel-label': {
    fontSize: '14px'
    }
}

function GetCheckboxComponent(status, isEnabled, label, isChecked, answerId, handleCheck) {
    if (status === 2) {
        return <FormControlLabel sx={radioSx} control={<CorrectCheckbox/>} label={label} disabled={!isEnabled} checked={isChecked}
                                 key={answerId} onChange={() => handleCheck(answerId)}/>
    } else if (status === 3) {
        return <FormControlLabel sx={radioSx} control={<InvalidCheckbox/>} label={label} disabled={!isEnabled} checked={isChecked}
                                 key={answerId} onChange={() => handleCheck(answerId)}/>
    }

    return <FormControlLabel sx={radioSx} control={<Checkbox/>} label={label} disabled={!isEnabled}
                             checked={isChecked || status === 1} key={answerId} onChange={() => handleCheck(answerId)}/>
}

function GetRadioComponent(status, isEnabled, label, isChecked, answerId, handleCheck) {
    if (status === 2) {
        return <FormControlLabel sx={radioSx} label={label} disabled={!isEnabled} checked={isChecked} control={<CorrectRadio/>}
                                 value={answerId} onChange={() => handleCheck(answerId)}/>
    } else if (status === 3) {
        return <FormControlLabel sx={radioSx} label={label} disabled={!isEnabled} checked={isChecked} control={<InvalidRadio/>}
                                 value={answerId} onChange={() => handleCheck(answerId)}/>
    }

    return <FormControlLabel label={label} sx={radioSx} disabled={!isEnabled} checked={isChecked || status === 1} control={<Radio/>}
                             value={answerId} onChange={() => handleCheck(answerId)}/>
}

function AnswersList(props) {

    if (props.isMultipleAnswer) {
        return (
            <FormGroup>
                {props.answers.map((answer, index) => {
                    return GetCheckboxComponent(answer.status, props.isEnabled, answer.text,
                        answer.isPicked, answer.id, props.updateAnswerChoice)
                })}
            </FormGroup>
        )
    }

    return (
        <RadioGroup
            aria-labelledby="questionsAnswers"
            name="answers-radio-buttons">
            {props.answers.map((answer, index) => {
                return GetRadioComponent(answer.status, props.isEnabled, answer.text,
                    answer.isPicked, answer.id, props.updateAnswerChoice)
            })}
        </RadioGroup>
    )
}

AnswersList.propTypes = {
    answers: PropType.array.isRequired,
    isMultipleAnswer: PropType.bool.isRequired,

    isEnabled: PropType.bool.isRequired,

    updateAnswerChoice: PropType.func.isRequired
};

export default AnswersList;