import {Checkbox, Radio} from "@mui/material";
import styled from "@emotion/styled";

export const CorrectCheckbox = styled(Checkbox)({
    color: 'var(--success-primary)',
    '&.Mui-checked': {
        color: 'var(--success-primary)',
    },
    '&.Mui-disabled': {
        color: 'var(--success-transparent)',
    },
});

export const InvalidCheckbox = styled(Checkbox)({
    color: 'var(--color-red)',
    '&.Mui-checked': {
        color: 'var(--color-red)',
    },
    '&.Mui-disabled': {
        color: 'var(--color-red-transparent)',
    },
});

export const CorrectRadio = styled(Radio)({
    color: 'var(--success-primary)',
    '&.Mui-checked': {
        color: 'var(--success-primary)',
    },
    '&.Mui-disabled': {
        color: 'var(--success-transparent)',
    },
});

export const InvalidRadio = styled(Radio)({
    color: 'var(--color-red)',
    '&.Mui-checked': {
        color: 'var(--color-red)',
    },
    '&.Mui-disabled': {
        color: 'var(--color-red-transparent)',
    },
});