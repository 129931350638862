import PropTypes from "prop-types";
import React from "react";
import styleClasses from "./ManagementPageHeader.module.css";
import {ElementButton} from "./styles";

export default function ManagementPageHeader(props) {
    return (
        <div className={styleClasses.elementsPosition}>
            <ElementButton onClick={props.handleLeftSideButtonClick}>
                {props.leftSideButtonText}</ElementButton>
            <ElementButton startIcon={props.rightSideButtonIcon}
                           onClick={props.handleRightSideButtonClick}
                           type={props.rightSideButtonType}>
                {props.rightSideButtonText}</ElementButton>
        </div>
    );
}

ManagementPageHeader.propTypes = {
    handleLeftSideButtonClick: PropTypes.func.isRequired,
    leftSideButtonText: PropTypes.string.isRequired,

    handleRightSideButtonClick: PropTypes.func,
    rightSideButtonText: PropTypes.string.isRequired,
    rightSideButtonIcon: PropTypes.element.isRequired,
    rightSideButtonType: PropTypes.string.isRequired,
}