import React, {useEffect, useState} from 'react';
import MainContainer from "../../../components/Containers/MainContainer/MainContainer";
import classes from "./MenuPage.module.css";
import ProfessionsTreeViewPicker from "../../../components/ProfessionsTreeViewPicker/ProfessionsTreeViewPicker";
import {ChallengeFrameSwitch, StartChallengeButton} from "./styles";
import ExamChallengeFrame from "../../../components/ExamChallengeFrame/ExamChallengeFrame";
import TrainingChallengeFrame from "../../../components/TrainingChallengeFrame/TrainingChallengeFrame";
import {requestHandler} from "../../../infrastructure/requestHandler";
import {useDispatch, useSelector} from "react-redux";
import {CheckMyProfessionExists} from "../../../actions/MyActions";
import {CreateChallenge} from "../../../actions/ChallengeActions";
import ActiveChallengeAlert from "../../../components/ActiveChallengeAlert/ActiveChallengeAlert";
import {useHistory} from "react-router-dom";
import {CHALLENGE_PAGE_HISTORY} from "../../../utils/routeNames";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {useMediaQuery} from "@mui/material";

export default function MenuPage() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [checked, setChecked] = useState([]);
    const [examSelected, setExamSelection] = useState(false);
    const [totalQuestionsCount, setTotalQuestionsCount] = useState(0);
    const [questionsCount, setQuestionsCount] = useState(0);
    const [challengeComplexity, setChallengeComplexity] = useState(2);
    const [challengeCompilation, setChallengeCompilation] = useState(1);
    const [showAnswersResult, setShowAnswersResult] = useState(false);

    const [activeUserChallenge, setActiveUserChallenge] = useState(undefined);
    const [activeChallengeState, setActiveChallengeState] = useState(false);

    const matches = useMediaQuery('(max-width:1050px)');

    const loading = useSelector(state => state.LoadingReduce);

    useEffect(() => {
        const challengeFunction = async () => {
            const data = await requestHandler(CheckMyProfessionExists(), dispatch)

            setActiveUserChallenge(data.challengeId);
        }

        challengeFunction();
    }, [activeChallengeState]);

    const handleExamSelection = () => {
        setExamSelection(!examSelected);
    }

    const handleChallengeStarting = async () => {
        const challengeId = await requestHandler(CreateChallenge(
            examSelected,
            questionsCount,
            challengeComplexity,
            challengeCompilation,
            checked,
            showAnswersResult), dispatch);

        history.push(CHALLENGE_PAGE_HISTORY + challengeId);
    }

    const resumeChallenge = (challengeId) => {
        history.push(CHALLENGE_PAGE_HISTORY + challengeId);
    }

    return (
        <React.Fragment>
            {loading.isLoading && <LoadingSpinner/>}
            <MainContainer alignment={matches ? "vertical" : "horizontal"}>
                <div className={classes.leftside}>
                    <ProfessionsTreeViewPicker checked={checked}
                                               setChecked={setChecked}
                                               setTotalQuestionsCount={setTotalQuestionsCount}/>
                </div>
                <div className={classes.rightside}>
                    <div className={classes.framesChoice}>
                        <span>Тренировка</span>
                        <ChallengeFrameSwitch checked={examSelected}
                                              onChange={handleExamSelection}/>
                        <span>Экзамен</span>
                    </div>
                    {examSelected ?
                        <ExamChallengeFrame totalQuestionsCount={totalQuestionsCount}
                                            questionsCount={questionsCount}
                                            challengeCompilation={challengeCompilation}
                                            setChallengeCompilation={setChallengeCompilation}
                                            challengeComplexity={challengeComplexity}
                                            setChallengeComplexity={setChallengeComplexity}
                                            setQuestionsCount={setQuestionsCount}
                                            setShowAnswersResult={setShowAnswersResult}
                                            showAnswersResult={showAnswersResult}
                                            examSelected={examSelected}/> :
                        <TrainingChallengeFrame totalQuestionsCount={totalQuestionsCount}
                                                questionsCount={questionsCount}
                                                challengeCompilation={challengeCompilation}
                                                setChallengeCompilation={setChallengeCompilation}
                                                challengeComplexity={challengeComplexity}
                                                setChallengeComplexity={setChallengeComplexity}
                                                setQuestionsCount={setQuestionsCount}
                                                setShowAnswersResult={setShowAnswersResult}
                                                showAnswersResult={showAnswersResult}
                                                examSelected={examSelected}/>}
                    <div className={classes.buttonsArea}>
                        {activeUserChallenge ?
                            <ActiveChallengeAlert activeChallengeState={activeChallengeState}
                                                  updateActiveChallengeState={setActiveChallengeState}
                                                  activeUserChallenge={activeUserChallenge}
                                                  resumeChallenge={resumeChallenge}/> :
                            <StartChallengeButton
                                disabled={totalQuestionsCount === 0 || questionsCount === 0}
                                onClick={handleChallengeStarting}>Начать</StartChallengeButton>}
                    </div>
                </div>
            </MainContainer>
        </React.Fragment>
    );
}