import styled from "@emotion/styled";
import {Button} from "@mui/material";

export const ElementButton = styled(Button)(
    ({theme}) => ({
        color: "white",
        height: "40px",
        backgroundColor: "var(--color-red)",
        '&:hover': {
            backgroundColor: "var(--color-red-hover)",
        },
        marginRight: "15px"
    })
);