import React from 'react';
import classes from './MainContainer.module.css'

import PropTypes from 'prop-types'


export default function MainContainer(props) {

    return (
        <div className={classes.fullSizeContainer}>
            <div className={props.alignment === 'vertical' ? classes.columnContainer : classes.rowContainer}>
                {props.children}
            </div>
        </div>
    );
}

MainContainer.propTypes = {
    children: PropTypes.element.isRequired
}