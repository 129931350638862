import React, {useEffect, useState} from "react";
import CheckboxTree from 'react-checkbox-tree';
import classes from './ProfessionsTreeViewPicker.module.css';
import {PropTypes} from 'prop-types';

import {requestHandler} from "../../infrastructure/requestHandler";
import {GetMyProfessions} from "../../actions/MyActions";
import {useDispatch} from "react-redux";

import 'react-checkbox-tree/lib/react-checkbox-tree.css';

function initTreeViewNode(value, label, icon, initChildren) {
    let treeNode = {
        value: value,
        label: label,
        icon: icon
    };

    if (initChildren) {
        treeNode.children = [];
    }

    return treeNode;
}

export default function ProfessionsTreeViewPicker(props) {
    const [nodes, setNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [clicked, setClicked] = useState({});
    const [sectionQuestions, setSectionQuestions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const asyncFunc = async () => {
            const data = await requestHandler(GetMyProfessions(), dispatch);
            let tempSectionQuestions = [];

            const localProfessions = data.professions.map(prof => {
                const headProfession = initTreeViewNode(prof.id, prof.title, <i className="fas fa-hard-hat"/>, true);
                headProfession.children = prof.topics.map(topic => {
                    const headTopic = initTreeViewNode(topic.id, topic.title, <i className="fas fa-cog"/>, true);
                    headTopic.children = topic.topicSections.map(section => {
                        let headSection = initTreeViewNode(section.id, section.title, <i
                            className="fas fa-wrench"/>, false);

                        tempSectionQuestions.push({
                            id: section.id,
                            questionsCount: section.questionsCount,
                            questionIds: section.questions
                        });

                        return headSection;
                    });

                    return headTopic;
                });

                return headProfession;
            });

            setNodes(localProfessions);
            setSectionQuestions(tempSectionQuestions);
        }

        asyncFunc();
    }, [])

    useEffect(() => {
        const selectedSections = sectionQuestions.filter(s => props.checked.some(c => c === s.id));

        const sectionedQuestions =[];
         selectedSections.map(s => {
             Array.prototype.push.apply(sectionedQuestions, s.questionIds);
        });

        const groupedQuestions = sectionedQuestions.filter((item, index) => sectionedQuestions.indexOf(item) === index);

        props.setTotalQuestionsCount(groupedQuestions.length);
    }, [props.checked])

    const checkNode = (checked) => {
        props.setChecked(checked)
    }

    return (
        <div className={classes.treeView}>
            <CheckboxTree
                nodes={nodes}
                checked={props.checked}
                expandOnClick={true}
                iconsClass="fa5"
                showExpandAll={true}
                expanded={expanded}
                onlyLeafCheckboxes={true}
                onClick={clicked => setClicked(clicked)}
                onCheck={checkNode}
                onExpand={expanded => setExpanded(expanded)}/>
        </div>
    )
}

ProfessionsTreeViewPicker.propTypes = {
    checked: PropTypes.array.isRequired,
    setChecked: PropTypes.func.isRequired,
    setTotalQuestionsCount: PropTypes.func.isRequired
}