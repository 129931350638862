import React from 'react';
import styles from './TableHeadContainer.module.css'

import PropTypes from "prop-types"

export default function TableHeadContainer(props) {

    return (
        <div className={styles.header}>
            {props.children}
        </div>
    );
}

TableHeadContainer.propTypes = {
    children: PropTypes.element.isRequired
}