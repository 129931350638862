import {NumericInput, StyledSlider} from "./styles";

export default function QuestionsCountPicker(props) {

    const handleSliderChange = (event, newValue) => {
        props.setValue(newValue);
    };

    const handleInputChange = (event) => {
        props.setValue(event.target.value === '' ? '' : Number(event.target.value));
    };

    const marks = [
        {
            value: 0,
            label: 0,
        },
        {
            value: props.totalQuestionsCount,
            label: props.totalQuestionsCount,
        },
    ];

    return (
        <>
            {props.showQuestionsPickingLine ? <StyledSlider
                value={typeof props.value === 'number' ? props.value : 0}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                max={props.totalQuestionsCount}
                marks={marks}/> : <></>}
            <div>
                <NumericInput
                    value={props.value}
                    size="small"
                    label="Количество вопросов"
                    onChange={handleInputChange}
                    variant="outlined"
                    disabled={!props.showQuestionsPickingLine}
                    inputProps={{
                        step: 5,
                        min: 0,
                        max: props.totalQuestionsCount,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                    }}
                />
            </div>
        </>
    )
}