import PropTypes from "prop-types";
import React, {useState} from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classes from "./TableAction.module.css";
import {ClickAwayListener, IconButton, MenuItem, MenuList, Paper, Popover} from "@mui/material";
import Typography from "@mui/material/Typography";

function TableAction({actionItems, anchor, setAnchorEl}) {

    const [] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <IconButton aria-haspopup="true"
                        aria-controls="menu"
                        onClick={handleClick} size="small">
                <MoreVertIcon fontSize="small"/>
            </IconButton>
            <Popover open={Boolean(anchor)}
                     anchorEl={anchor}
                     onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'right'
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'right'
                     }}>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                            {actionItems.map(menuItem => {
                                return (
                                    <MenuItem className={classes.tableAction} onClick={menuItem.handleClickEvent}
                                              key={menuItem.key}>
                                        <Typography variant="inherit">{menuItem.label}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popover>
        </div>
    );
}

TableAction.propTypes = {
    actionItems: PropTypes.array.isRequired,
    setAnchorEl: PropTypes.func.isRequired,
    anchor: PropTypes.bool.isRequired
};

export default TableAction;