import {styled} from '@mui/material/styles';
import {Button} from "@mui/material";

export const CorrectQuestionButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    textAlign: 'left',
    backgroundColor: 'var(--success-primary)',
    width: '100%',
    textTransform: 'none',
    margin: '3px',
    '&:hover': {
        backgroundColor: 'var(--success-hover)',
    },
    '&.Mui-disabled': {
        backgroundColor: '#60a462',
        color: 'white'
    }
}));

export const InvalidQuestionButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    backgroundColor: 'var(--color-red)',
    textAlign: 'left',
    width: '100%',
    textTransform: 'none',
    margin: '3px',
    '&:hover': {
        backgroundColor: 'var(--color-red-hover)',
    },
    '&.Mui-disabled': {
        backgroundColor: '#b35050',
        color: 'white'
    }
}));

export const DefinedQuestionButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    backgroundColor: 'var(--background-color)',
    textAlign: 'left',
    width: '100%',
    textTransform: 'none',
    margin: '3px',
    '&:hover': {
        backgroundColor: 'var(--background-color-hover)',
    },
    '&.Mui-disabled': {
        backgroundColor: '#7691b5',
        color: 'white'
    }
}));

export const NotDefinedQuestionButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    backgroundColor: 'var(--color-gray)',
    textAlign: 'left',
    width: '100%',
    textTransform: 'none',
    margin: '3px',
    '&:hover': {
        backgroundColor: 'var(--color-gray-hover)',
    },
    '&.Mui-disabled': {
        backgroundColor: '#b1b1b1',
        color: 'white'
    }
}));