import React from 'react';
import classes from './TableBodyContainer.module.css'

import PropTypes from 'prop-types'


export default function TableBodyContainer(props) {

    return (
        <div className={classes.container}>
                {props.children}
        </div>
    );
}

TableBodyContainer.propTypes = {
    children: PropTypes.element.isRequired
}