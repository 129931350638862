import * as action from "./types/ActionTypes";
import * as constants from "../utils/constants";

import api from "../infrastructure/Api";

export const LoginUser = ({Login, Password}) => async (dispatch) => {
    const data = await api.loginUser({Login, Password});

    localStorage.setItem(
        constants.LOCAL_AUTHORITY_KEY,
        JSON.stringify({
            username: data.username,
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            permission: data.permission,
            company: data.company
        })
    );

    dispatch({
        type: action.LOGIN_USER,
        username: data.username,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        permission: data.permission,
        company: data.company
    });

    return !!data.accessToken && !!data.refreshToken;
};

export const LogoutUser = () => async (dispatch) => {
    localStorage.removeItem(constants.LOCAL_AUTHORITY_KEY);
    dispatch({
        type: action.LOGOUT_USER,
    });
};
