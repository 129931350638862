import * as action from "../actions/types/ActionTypes";
import {TryRestoreToken} from "../actions/TokenActions";
import {login} from "../utils/apiRoutes";

export const requestHandler = async function (handler, dispatch) {
    try {
        await dispatch({
            type: action.PAGE_IS_LOADING,
        });

        return await dispatch(handler);
    } catch (exception) {
        let errorMessages = [];

        const response = exception.response;
        if (response.status && response.status === 401 && !response.config.url !== login()) {
            let result = await dispatch(TryRestoreToken());
            if (result === true) {
                return requestHandler(handler, dispatch);
            }
        }

        const exceptionData = response.data;
        if (exceptionData.errors) {
            Object.keys(exceptionData.errors).forEach((prop) => {
                errorMessages.push(exceptionData.errors[prop]);
            });
        } else {
            errorMessages.push(exceptionData.message);
        }

        await dispatch({
            type: action.SNACKBAR_ERROR_APPEARED,
            messages: errorMessages,
        });
    } finally {
        await dispatch({
            type: action.PAGE_NOT_LOADING,
            isLoading: false,
        });

        await dispatch({
            type: action.SNACKBAR_CLEAR,
        });
    }
};