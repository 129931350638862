import React, {useEffect, useState} from 'react';
import PropType from 'prop-types';
import {requestHandler} from "../../infrastructure/requestHandler";
import {useDispatch} from "react-redux";
import {Alert, Link} from "@mui/material";
import {GetQuestionExplanation} from "../../actions/ChallengeActions";

import classes from './QuestionExplanation.module.css';

function QuestionExplanation(props) {
    const [ordinance, setOrdinance] = useState();
    const [chapter, setChapter] = useState();
    const [explanation, setExplanation] = useState();

    const [allowDisplay, setAllowDisplay] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setAllowDisplay(false);
    }, [props.questionId, props.challengeId])

    const handleFetchExplanation = async () => {
        const data = await requestHandler(GetQuestionExplanation(props.challengeId, props.questionId), dispatch);

        setChapter(data.chapter);
        setOrdinance(data.ordinance);
        setExplanation(data.explanation);
        setAllowDisplay(true);
    }

    if (!allowDisplay) {
        return (
            <Link sx={{'color': '#000000'}}
                  component="button"
                  variant="body2"
                  onClick={handleFetchExplanation}>
                Показать объяснение
            </Link>
        )
    }

    return (
        <Alert severity="info">
            <div className={classes.headerText}>{ordinance} - {chapter}</div>
            <div className={classes.explanationText}>{explanation}</div>
        </Alert>
    )
}

QuestionExplanation.propTypes = {
    questionId: PropType.string.isRequired,
    challengeId: PropType.string.isRequired,
}

export default QuestionExplanation;