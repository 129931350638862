import * as actions from "../actions/types/ActionTypes"

const defaultState = {
    messages: [],
    variant: null
}

const SnackbarReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.SNACKBAR_ERROR_APPEARED :
            return {
                ...state,
                variant: "error",
                messages: action.messages
            };
        case actions.SNACKBAR_SUCCESS_APPEARED :
            return {
                ...state,
                variant: "success",
                messages: action.messages
            };
        case actions.SNACKBAR_CLEAR :
            return {
                ...state,
                variant: null,
                messages: []
            }
        default:
            return state
    }
}

export default SnackbarReducer;