import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {MY_RESULTS_TAB_PAGE} from "../../../utils/routeNames";
import {GetMyChallenges} from "../../../actions/ChallengeActions";
import TableHeadContainer from "../../../components/Containers/TableHeadContainer/TableHeadContainer";
import MainContainer from "../../../components/Containers/MainContainer/MainContainer";
import TableBodyContainer from "../../../components/Containers/TableBodyContainer/TableBodyContainer";
import PreviewTable from "../../../components/PreviewTable/PreviewTable";
import {useDispatch, useSelector} from "react-redux";
import {SetChangePage, SetDefaultTableState, SetRowsPerPage, SortRowsByFields} from "../../../actions/TableActions";
import {TableCell, Typography} from "@mui/material";
import classes from './ChallengeResults.module.css';
import ActiveDot from "../../../components/ActiveDot/ActiveDot";
import ChallengeTableActions from "../../../components/ChallengeTableActions/ChallengeTableActions";
import {requestHandler} from "../../../infrastructure/requestHandler";
import ComplexityDot from "../../../components/StatusDot/ComplexityDot";
import {GetUserChallenges} from "../../../actions/UserActions";

function getChallengeStatusString(status) {
    if (status === 1) {
        return "Активен";
    } else if (status === 2) {
        return "Закончен";
    } else if (status === 3) {
        return "Отменен";
    } else if (status === 0) {
        return "Время вышло";
    }
}

const headCells = [
    {id: "StartDateTime", align: "left", label: "Дата прохождения", sortArea: "ascending"},
    {id: "Type", align: "left", label: "Тип", sortArea: "ascending"},
    {id: "Complexity", align: "center", label: "Уровень", sortArea: "ascending"},
    {id: "QuestionsCount", align: "right", label: "Количество вопросов", sortArea: "ascending"},
    {id: "SuccessAnswersPercentage", align: "right", label: "Результат", sortArea: "ascending"},
    {id: "Status", align: "left", label: "Статус", sortArea: "ascending"},
    {id: "Action", align: "right", label: "", sortArea: "none"}
];

function prepareTableCells(rowsData, setParentState, parentState) {
    let preparedCells = [];

    rowsData.map(row => {
        preparedCells.push([
            <TableCell className={classes.challengeDate}><Typography className={classes.textArea}>
                {row.startDateTime ? `${new Date(row.startDateTime).toLocaleDateString()} ${new Date(row.startDateTime).toLocaleTimeString()}` : ""}
            </Typography>
            </TableCell>,
            <TableCell className={classes.challengeType}><Typography
                className={classes.textArea}>{row.type === 1 ? "Тренировка" : "Экзамен"}</Typography></TableCell>,
            <TableCell align="center" className={classes.complexity}>{row.type === 1 ?
                <ComplexityDot status={row.complexity}/> : <></>}</TableCell>,
            <TableCell align="right" className={classes.questionsCount}><Typography
                className={classes.textArea}>{row.questionsCount}</Typography></TableCell>,
            <TableCell align="right" className={classes.challengeResult}><Typography
                className={classes.textArea}>{row.successAnswered} ({Math.round(row.successAnswersPercentage)}%)</Typography></TableCell>,
            <TableCell className={classes.status}>
                <Typography className={classes.textArea}>{getChallengeStatusString(row.status)}</Typography>
            </TableCell>,
            <TableCell align="right">{<ChallengeTableActions challengeId={row.id} setParentState={setParentState}
                                                             parentState={parentState}/>}</TableCell>,
        ]);
    })

    return preparedCells;
}

function dataHandlingFunction(handler, props) {
    return function (filteringState) {
        return function () {
            return handler({...props, ...filteringState})
        }
    };
}

export default function ChallengeResults(props) {
    const dispatch = useDispatch();
    const [stateUpdated, setUpdatingState] = useState(false);
    const [invokingFunction, setInvokingFunction] = useState(undefined);
    const [username, setUsername] = useState('USER RESULTS');

    const tableState = useSelector(state => state.TableReduce);
    const loggedInUserState = useSelector(state => state.LoginReduce);

    const location = useLocation();

    useEffect(() => {
        dispatch(SetDefaultTableState());
    }, []);

    useEffect(() => {
        if (location.pathname === MY_RESULTS_TAB_PAGE) {
            setInvokingFunction(() => dataHandlingFunction(GetMyChallenges, {}));
            setUsername(loggedInUserState.username.toUpperCase());
        } else {
            setInvokingFunction(() => dataHandlingFunction(GetUserChallenges, {id : props.match.params.id}));
            setUsername(props.location.state.username.toUpperCase());
        }
    }, [])

    useEffect(() => {
        if (invokingFunction !== undefined) {
            requestHandler(invokingFunction(tableState)(), dispatch);
        }
    }, [invokingFunction, stateUpdated])

    const handleSortAction = (orderBy, isAsc) => {
        dispatch(SortRowsByFields(orderBy, isAsc));
        setUpdatingState(!stateUpdated);
    }

    const handleChangePage = (event, newPage) => {
        dispatch(SetChangePage(newPage));
        setUpdatingState(!stateUpdated);
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(SetRowsPerPage(parseInt(event.target.value, 10)));
        setUpdatingState(!stateUpdated);
    };

    return (
        <>
            <MainContainer alignment='vertical'>
                <TableHeadContainer><strong>{username}</strong></TableHeadContainer>
                <TableBodyContainer>
                    <PreviewTable rowsPerPage={tableState.takeRows}
                                  orderBy={tableState.sortField}
                                  isAscending={tableState.sortAscending}
                                  handleSkippingRows={handleChangePage}
                                  handleSort={handleSortAction}
                                  currentPage={tableState.currentPage}
                                  itemsCount={tableState.tableTotalDataCount}
                                  tableHeaderItems={headCells}
                                  preparedTableData={prepareTableCells(tableState.tableData, setUpdatingState, stateUpdated)}
                                  handleTakingRows={handleChangeRowsPerPage}/>
                </TableBodyContainer>
            </MainContainer>
        </>
    )
}