import PropTypes from "prop-types";
import React from "react";
import {TextField} from "@mui/material";

function FormTextFieldElement(props) {
    return (
        <>
            <TextField
                name={props.name}
                disabled={props.disabled}
                value={props.value}
                onChange={props.onChangeHandler}
                error={props.touchedElement && Boolean(props.errorElement)}
                helperText={props.touchedElement && props.errorElement}
                label={props.label}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                {...props}/>
        </>);
}

FormTextFieldElement.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    touchedElement: PropTypes.element.isRequired,
    errorElement: PropTypes.element.isRequired
};

export default FormTextFieldElement;