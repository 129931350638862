import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import AlertLabel from "../AlertLabel/AlertLabel";
import QuestionsCountPicker from "../QuestionsCountPicker/QuestionsCountPicker";
import classes from './ExamChallengeFrame.module.css';

function ExamChallengeFrame(props) {

    useEffect(() => {
        if (props.examSelected) {
            props.setChallengeCompilation(2);
            props.setChallengeComplexity(2);
            props.setQuestionsCount(20);
            props.setShowAnswersResult(false);
        }
    }, [props.examSelected]);

    return (
        <div className={classes.frameContainer}>
            <div className={classes.questionsPicker}>
                <QuestionsCountPicker value={props.questionsCount}
                                      setValue={props.setQuestionsCount}
                                      totalQuestionsCount={props.totalQuestionsCount}
                                      showQuestionsPickingLine={false}/>
            </div>
            <AlertLabel isExam={true}
                        challengeComplexity={props.challengeComplexity}
                        challengeCompilation={props.challengeCompilation}/>
        </div>
    );
}

ExamChallengeFrame.propTypes = {
    totalQuestionsCount: PropTypes.number.isRequired,
    questionsCount: PropTypes.number.isRequired,
    setQuestionsCount: PropTypes.func.isRequired,

    challengeComplexity: PropTypes.number.isRequired,
    setChallengeComplexity: PropTypes.func.isRequired,

    challengeCompilation: PropTypes.number.isRequired,
    setChallengeCompilation: PropTypes.func.isRequired,

    showAnswersResult: PropTypes.bool.isRequired,
    setShowAnswersResult: PropTypes.func.isRequired
}

export default ExamChallengeFrame;