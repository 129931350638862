import React from 'react';
import PropTypes from 'prop-types';
import ClickableQuestion from "../ClickableQuestion/ClickableQuestion";
import classes from './ChallengeQuestionsList.module.css';

function ChallengeQuestionsList(props) {

    return (
        <div className={classes.questionsList}>
            {props.questions.map((question, index) => {
                return <ClickableQuestion answers={question.answers}
                                                index={index}
                                                currentActiveQuestion={index === props.currentActiveQuestionNumber}
                                                setPresentingQuestion={props.handleChangePresentingQuestion}
                                                questionText={question.text}
                                                stateUpdated={props.stateUpdated}/>
            })}
        </div>
    )
}

ChallengeQuestionsList.propTypes = {
    questions: PropTypes.array.isRequired,
    handleChangePresentingQuestion: PropTypes.func.isRequired,
    currentActiveQuestionNumber: PropTypes.number.isRequired,
    stateUpdated: PropTypes.bool.isRequired
}

export default ChallengeQuestionsList;