import {useEffect, useState} from "react";
import {
    CorrectQuestionButton,
    DefinedQuestionButton,
    InvalidQuestionButton,
    NotDefinedQuestionButton
} from "./styles";
import classes from './ClickableQuestion.module.css';

const notDefined = 0;
const defined = 1;
const correct = 2;
const invalid = 3;

const definedPredicate = ((answer) => answer.status === defined);
const correctPredicate = ((answer) => answer.status === correct);
const invalidPredicate = ((answer) => answer.status === invalid);

export default function ClickableQuestion(props) {
    const [status, setStatus] = useState(notDefined);

    useEffect(() => {
        if (props.answers.some(invalidPredicate)) {
            setStatus(invalid);
        } else if (props.answers.some(correctPredicate)) {
            setStatus(correct);
        } else if (props.answers.some(definedPredicate)) {
            setStatus(defined);
        }
    }, [props.stateUpdated])

    const handleClick = () => {
        props.setPresentingQuestion(props.index);
    }

    const displayingText = <p className={classes.splitedString}><span>{props.index + 1}. {props.questionText}</span></p>;

    return (
        <>
            {status === notDefined ?
                <NotDefinedQuestionButton disabled={props.currentActiveQuestion}
                                          onClick={handleClick}>{displayingText}</NotDefinedQuestionButton> :
                status === defined ?
                    <DefinedQuestionButton disabled={props.currentActiveQuestion}
                                           onClick={handleClick}>{displayingText}</DefinedQuestionButton> :
                    status === correct ?
                        <CorrectQuestionButton disabled={props.currentActiveQuestion}
                                               onClick={handleClick}>{displayingText}</CorrectQuestionButton> :
                        <InvalidQuestionButton disabled={props.currentActiveQuestion}
                                               onClick={handleClick}>{displayingText}</InvalidQuestionButton>}
        </>
    );
}
