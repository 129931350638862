import React from "react";
import {TextField} from "@mui/material";
import styled from "@emotion/styled";

export const FormField = styled(TextField)(
    ({theme}) => ({
        'margin-bottom': "20px",
        'width': "100%"
    })
);

export default function FormTextareaElement({
                                     name, label, disabled, value, onChangeHandler,
                                     touchedElement, errorElement, minRows
                                 }) {
    return (
        <>
            <FormField
                name={name}
                disabled={disabled}
                value={value}
                onChange={onChangeHandler}
                error={touchedElement && Boolean(errorElement)}
                helperText={touchedElement && errorElement}
                minRows={minRows}
                label={label}
                InputLabelProps={{
                    shrink: true,
                }}
                multiline
                variant="outlined"/>
        </>);
}