import * as actions from "../actions/types/ActionTypes";

const defaultState = {
    accessToken: null,
    refreshToken: null,
    isAuth: false,
    username: null,
    permission: null,
    company: null
};

const LoginReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.LOGIN_USER:
            return {
                ...state,
                username: action.username,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                permission: action.permission,
                isAuth: !!action.accessToken && !!action.refreshToken,
                company: action.company,
            };
        case actions.LOGOUT_USER:
            return {
                ...state,
                username: null,
                accessToken: null,
                refreshToken: null,
                permission: null,
                isAuth: false,
                company: null,
            };
        case actions.REFRESH_TOKEN:
            return {
                ...state,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                isAuth: !!action.accessToken && !!action.refreshToken,
            }
        default:
            return state;
    }
};

export default LoginReducer;