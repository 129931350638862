import MenuPage from "./Menu/MenuPage";
import ChallengePage from "./Challenge/ChallengePage";

import {
    CHALLENGE_PAGE,
    COMPANY_EMPLOYEES_TAB_PAGE,
    MENU_TAB_PAGE,
    MY_RESULTS_TAB_PAGE,
    USER_CHALLENGES_PAGE, USERS_MANAGEMENT_ROUTE_PAGE,
} from "../../utils/routeNames";
import {ADMIN_OBSERVER, ADMIN_OBSERVER_USER, OBSERVER, USER} from "../../utils/roles";
import ChallengeResults from "./ChallengeResults/ChallengeResults";
import UsersTable from "./UsersTable/UsersTable";
import UserManagementPage from "./UserManagementPage/UserManagementPage";

export default [
    ManageApplicationRoutes(MENU_TAB_PAGE, MenuPage, false, 0, ADMIN_OBSERVER_USER, "Решение тестов"),
    ManageApplicationRoutes(MY_RESULTS_TAB_PAGE, ChallengeResults, false, 1, USER, "Результаты"),
    ManageApplicationRoutes(COMPANY_EMPLOYEES_TAB_PAGE, UsersTable, false, 1, ADMIN_OBSERVER, "Сотрудники компании"),
    ManageApplicationRoutes(CHALLENGE_PAGE, ChallengePage, false, -1, ADMIN_OBSERVER_USER, ""),
    ManageApplicationRoutes(USER_CHALLENGES_PAGE, ChallengeResults, false, -1, ADMIN_OBSERVER, ""),
    ManageApplicationRoutes(USERS_MANAGEMENT_ROUTE_PAGE, UserManagementPage, false, -1, OBSERVER, ""),
];

function ManageApplicationRoutes(path, component, isExact, tabIndicator, acceptedRole, headerLabel) {
    return {
        path,
        component,
        isExact,
        tabIndicator,
        acceptedRole,
        headerLabel
    };
}