import React, {useEffect} from "react";
import './App.css';

import AppRouter from "./AppRouter";
import {useSnackbar} from "notistack";
import * as constants from "../utils/constants";

import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {HOME_PAGE} from "../utils/routeNames";
import {LOGIN_USER} from "../actions/types/ActionTypes";

function App() {

    const {enqueueSnackbar} = useSnackbar();

    const notifications = useSelector(store => store.SnackbarReduce);

    useEffect(() => {
        notifications.messages.forEach(message => {
            enqueueSnackbar(message, {
                variant: notifications.variant,
            })
        });

    }, [notifications])

    return (
        <>
            <AppRouter/>
        </>
    );
}

export default App;
