import React, {useEffect, useState} from "react";
import {inputElement, manageButtonSx, modalSx, paperSx, updateButtonSx} from "./styles";
import PropTypes from "prop-types";
import styleClasses from "./PasswordChangeModal.module.css";
import FormTextFieldElement from "../FormTextFieldElement/FormTextFieldElement";
import * as yup from "yup";
import {useFormik} from "formik";
import {Backdrop, Button, Fade, Modal, TextField} from "@mui/material";
import Box from "@mui/material/Box";

function PasswordChangeModal(props) {
    const [credentialsData, setCredentialsData] = useState({
        login: "",
        password: "",
        repeatPassword: ""
    });

    useEffect(() => {
        setCredentialsData({
            login: props.login,
            password: "",
            repeatPassword: ""
        })
    }, [props.isModalOpened])

    const handleOpen = () => {
        props.openModal(true);
    };

    const handleClose = () => {
        props.openModal(false);
    };

    const updateUserCredentialsHandler = (values) => {
        const {login, password} = values;
        props.updateUserCredentialsHandler(props.userId, login, password);
    }

    const validationSchema = yup.object({
        login: yup.string().required("User's login is required"),
        password: yup.string().required('Password is required'),
        repeatPassword: yup.string().required('Repeating is required')
            .oneOf([yup.ref('password'), null], 'Passwords not matches')
    });

    const formik = useFormik({
        onSubmit: updateUserCredentialsHandler,
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            login: credentialsData.login,
            password: credentialsData.password,
            repeatPassword: credentialsData.repeatPassword
        },
    });


    return (
        <div>
            <div className={styleClasses.mainElements}>
                <TextField disabled
                           variant="outlined"
                           label="Логин"
                           value={props.login}/>
                <Button variant="outlined" sx={manageButtonSx} onClick={handleOpen}>
                    Управление доступом
                </Button>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                sx={modalSx}
                open={props.isModalOpened}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={props.isModalOpened}>
                    <Box sx={paperSx}>
                        <label className={styleClasses.questionHeaderLabel}>
                            Измените логин и пароль пользователя
                        </label>
                        <div className={styleClasses.separationLine}/>
                        <form onSubmit={formik.handleSubmit} className={styleClasses.formArea}>
                            <FormTextFieldElement onChangeHandler={formik.handleChange}
                                                  touchedElement={formik.touched.login}
                                                  errorElement={formik.errors.login}
                                                  value={formik.values.login}
                                                  label="Логин"
                                                  sx={inputElement}
                                                  disabled={false}
                                                  name="login"/>
                            <FormTextFieldElement onChangeHandler={formik.handleChange}
                                                  touchedElement={formik.touched.password}
                                                  errorElement={formik.errors.password}
                                                  value={formik.values.password}
                                                  label="Пароль"
                                                  type="password"
                                                  sx={inputElement}
                                                  disabled={false}
                                                  name="password"/>
                            <FormTextFieldElement onChangeHandler={formik.handleChange}
                                                  touchedElement={formik.touched.repeatPassword}
                                                  errorElement={formik.errors.repeatPassword}
                                                  value={formik.values.repeatPassword}
                                                  label="Повторите пароль"
                                                  type="password"
                                                  sx={inputElement}
                                                  disabled={false}
                                                  name="repeatPassword"/>
                            <Button variant="outlined"
                                    type="submit"
                                    sx={updateButtonSx}>
                                Обновить логин и пароль
                            </Button>
                        </form>
                        <div className={styleClasses.separationLine}/>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

PasswordChangeModal.propTypes = {
    login: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    updateUserCredentialsHandler: PropTypes.func,

    isModalOpened: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired
}

export default PasswordChangeModal;