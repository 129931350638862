import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

export const ContinueChallengeButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    backgroundColor: 'var(--success-primary)',
    width: '50%',
    marginRight: '10px',
    '&:hover': {
        backgroundColor: 'var(--success-hover)',
    }
}));

export const CancelChallengeButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    backgroundColor: 'var(--color-yellow)',
    width: '50%',
    marginLeft: '10px',
    '&:hover': {
        backgroundColor: 'var(--color-yellow-hover)',
    }
}));