import * as actions from "../actions/types/ActionTypes";

const defaultState = {
    isLoading: false
}

const LoadingReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.PAGE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case actions.PAGE_NOT_LOADING:
            return {
                ...state,
                isLoading: false
            };
        default :
            return state;
    }
}

export default LoadingReducer;