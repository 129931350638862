import {Alert, AlertTitle} from "@mui/material";
import {CancelChallengeButton, ContinueChallengeButton} from "./styles";
import classes from './ActiveChallengeAlert.module.css';
import {requestHandler} from "../../infrastructure/requestHandler";
import {useDispatch} from "react-redux";
import {CancelChallenge} from "../../actions/ChallengeActions";

export default function ActiveChallengeAlert(props) {
    const dispatch = useDispatch();

    const handleCancel = async () => {
        await requestHandler(CancelChallenge(props.activeUserChallenge), dispatch);
        props.updateActiveChallengeState(!props.activeChallengeState);
    }

    const handleResume = async () => {
        props.resumeChallenge(props.activeUserChallenge);
    }

    return (
        <div className={classes.activeChallengeContainer}>
            <Alert severity="warning">
                <AlertTitle>Продолжить прохождение?</AlertTitle>
                <strong>Внимание!</strong> У вас есть неоконченный тест. Нажмите "Продолжить", чтобы продолжить его
                прохождение или нажмите кнопку "Отмена" для его окончания.
            </Alert>
            <div className={classes.surveyButtonArea}>
                <ContinueChallengeButton
                    onClick={handleResume}>Продолжить</ContinueChallengeButton>
                <CancelChallengeButton
                    onClick={handleCancel}>Отмена</CancelChallengeButton>
            </div>
        </div>
    );
}