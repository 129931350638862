import {TableRow} from "@mui/material";
import {styled} from "@mui/material/styles";
// import TableRow from "@material-ui/core/TableRow";

// export const StrippedTableRow = withStyles((theme) => ({
//     root: {
//         "&:nth-of-type(odd)": {
//             backgroundColor: "var(--container-primary)"
//         }
//     }
// }))(TableRow);

export const StrippedTableRow = styled(TableRow)(
    ({theme}) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "var(--container-primary)"
            }
        }
    })
);