import {IconButton, Tab, Tabs} from "@mui/material";
import {styled} from "@mui/material/styles";
import LogoutIcon from '@mui/icons-material/Logout';

export const HeaderTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    width: '100%',
    color: "#434343",
    justifyContent: 'space-around',
    '& .MuiTabs-flexContainer': {
        padding: '0 12rem',
        justifyContent: 'space-evenly',
    },
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'var(--color-yellow)',
        marginTop: '12px',
        top: '0px'
    },
    '& .MuiTabs-indicatorSpan': {
        backgroundColor: 'var(--color-yellow)',
    },
});

export const HeaderTab = styled((props) => <Tab {...props}><span>{props.label}</span></Tab>)(
    ({theme}) => ({
        color: "#8a8a8a",
        fontSize: "12px",
        marginTop: '12px',
        '&.Mui-selected': {
            color: "#000000",
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'var(--color-yellow)',
        },
    }),
);

export const LogoutButton = styled((props) => <IconButton {...props}><LogoutIcon/></IconButton>)(
    ({theme}) => ({
        color: 'var(--color-yellow)',
    })
);