import {AppBar, Toolbar} from "@mui/material";
import {HeaderTab, HeaderTabs, LogoutButton} from "./styles";
import {useHistory} from "react-router-dom";
import classes from "./Header.module.css";
import logo from "../../static/mainlogo.png";
import * as constants from "../../utils/constants";
import {useDispatch} from "react-redux";
import {requestHandler} from "../../infrastructure/requestHandler";
import {LogoutUser} from "../../actions/LoginActions";
import {LOGIN_PAGE} from "../../utils/routeNames";

export default function Header(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleChange = (routeUrl) => {
        history.push(routeUrl)
    }

    const logoutUser = async () => {
        await requestHandler(LogoutUser(), dispatch);
        history.push(LOGIN_PAGE);
    }

    const authorities = JSON.parse(localStorage.getItem(constants.LOCAL_AUTHORITY_KEY));

    const leftSideTabs = props.routes.filter(route => route.tabIndicator === 0)
    const rightSideTabs = props.routes.filter(route => route.tabIndicator === 1)

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <HeaderTabs value={props.value}
                            onChange={props.handleChange}>
                    {leftSideTabs.map(r => {
                        if (r.acceptedRole.includes(authorities.permission)) {
                            return (<HeaderTab label={r.headerLabel}
                                               value={r.tabIndicator}
                                               onClick={() => handleChange(r.path)}/>);
                        }
                    })}
                    <div className={classes.centeredLabel}>
                        <img className={classes.logoImg} src={logo} alt="Логотип"/>
                        <h3 className={classes.logoLabel}>TESTS PORTAL</h3>
                    </div>
                    {rightSideTabs.map(r => {
                        if (r.acceptedRole.includes(authorities.permission)) {
                            return (<HeaderTab label={r.headerLabel}
                                               value={r.tabIndicator}
                                               onClick={() => handleChange(r.path)}/>);
                        }
                    })}
                </HeaderTabs>
                <div className={classes.profileManage}>
                    <h4 className={classes.username}>{authorities.username}</h4>
                    <LogoutButton onClick={logoutUser}/>
                </div>
            </Toolbar>
        </AppBar>
    );
}