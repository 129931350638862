import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, {useEffect, useState} from 'react';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate"
                                value={props.progressValue}/>
            </Box>
            <Box sx={{minWidth: 110}}>
                <Typography variant="body2" color="text.secondary">
                    {`${props.minutes} мин. ${props.seconds} сек.`}
                </Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    progressValue: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired
};

function TimelineProgressBar(props) {
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (props.allowStart) {
            const startDateTime = new Date(props.startDate);
            const endDate = new Date(startDateTime.getTime() + props.duration * 60000);
            const durationTime = endDate.getTime() - startDateTime.getTime();

            const interval = setInterval(function () {
                const now = durationTime - (new Date().getTime() - startDateTime.getTime());
                if (now <= 0) {
                    clearInterval(interval);
                    props.endTimeCallback();
                } else {
                    const percent = now / durationTime * 100;
                    setProgress(percent);

                    const leftTime = new Date(now);
                    setMinutes(leftTime.getUTCHours() * 60 + leftTime.getUTCMinutes());
                    setSeconds(leftTime.getSeconds());
                }
            }, 25); // the smaller this number, the more accurate the timer will be
        }
    }, []);

    return (
        <Box sx={{width: '80%'}}>
            <LinearProgressWithLabel minutes={minutes}
                                     seconds={seconds}
                                     progressValue={progress}/>
        </Box>
    );
}

TimelineProgressBar.propTypes = {
    startDate: PropTypes.object.isRequired,
    duration: PropTypes.number.isRequired,

    allowStart: PropTypes.bool.isRequired,
    endTimeCallback: PropTypes.func.isRequired
}

export default TimelineProgressBar;