import {styled} from '@mui/material/styles';
import {Button} from "@mui/material";

export const SubmitAndNextQuestionButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    marginTop: 'auto',
    backgroundColor: 'var(--color-orange)',
    width: '100%',
    '&:hover': {
        backgroundColor: 'var(--color-orange-hover)',
    }
}));

export const SubmitQuestionButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    marginTop: 'auto',
    backgroundColor: 'var(--success-primary)',
    width: '100%',
    '&:hover': {
        backgroundColor: 'var(--success-hover)',
    }
}));

export const NextQuestionButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    backgroundColor: 'var(--color-yellow)',
    width: '100%',
    marginTop: 'auto',
    '&:hover': {
        backgroundColor: 'var(--color-yellow-hover)',
    }
}));

export const FinishChallengeButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    marginTop: '5px',
    backgroundColor: 'var(--color-red)',
    width: '100%',
    '&:hover': {
        backgroundColor: 'var(--color-red-hover)',
    }
}));

export const GoBackButton = styled(Button)(({theme}) => ({
    display: 'block',
    color: 'black',
    marginTop: '5px',
    backgroundColor: 'var(--color-yellow)',
    width: '100%',
    '&:hover': {
        backgroundColor: 'var(--color-yellow-hover)',
    }
}));